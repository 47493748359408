import { Component, OnInit } from '@angular/core';
import {UtilitiesService} from "../../services/utilities.service";
import {MediaService} from "../../services/media.service";
import {AuthService} from "../../services/auth.service";
import {ClaimsService} from "../../services/claims.service";
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from "angular-animations";
import {NavService} from "../../services/nav.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-client-remittance',
  templateUrl: './client-remittance.component.html',
  styleUrls: ['./client-remittance.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 300}),
    fadeOutOnLeaveAnimation({ duration: 300})
  ]
})
export class ClientRemittanceComponent implements OnInit {

  public claim: any = {};

  public focus: any;

  public invoiceView = false;

  public released = false;

  constructor(
    public utils: UtilitiesService,
    public navService: NavService,
    public auth: AuthService,
    public claimsService: ClaimsService,
    public router: Router) {
    this.auth.get();
    this.navService.navTab = -1;
    this.claim = this.claimsService.claim;
    if (this.claim == undefined){
      this.router.navigate(['/remittance'])
    }
    this.released = this.claim.state == "approved" || this.claim.state == "rejected";
  }

  ngOnInit(): void {
  }

  /**
   * Click the claim file and open the invoice modal
   */
  public clickClaimFile(cf){
    this.focus = cf;
    this.openInvoice();
  }

  /**
   * Open the invoice modal
   */
  public openInvoice(){
    this.invoiceView = true;
  }

  /**
   * Close the invoice
   */
  public closeInvoice(){
    this.invoiceView = false;
  }

}
