<app-navbar></app-navbar>
<div class="default-page-container">
  <div class="default-page-internal">
    <div class="default-heading default-heading-sub-separator">Direct Bill</div>
    <div class="default-paragraph-small direct-bill-sub-heading">{{auth.token.providerName}}</div>
    <div class="direct-btn-cont">
      <button class="default-btn default-btn-clear default-control-btn" (click)="addItem()">+ Add Item</button>
    </div>
    <table class="direct-billing-table">
      <thead class="default-table-header">
      <tr>
        <th class="table-header direct-billing-header direct-billing-cell-normal">Invoice No.</th>
        <th class="table-header direct-billing-header direct-billing-cell-normal">Date</th>
        <th class="table-header direct-billing-header direct-billing-cell-normal">Membership No.</th>
        <th class="table-header direct-billing-header direct-billing-cell-small"><div class="tooltip-header">IRN<div id="direct-anchor" class="default-tooltip no-select direct-billing-tooltip" (mouseenter)="showTooltip()" (focus)="showTooltip()" (mouseleave)="hideTooltip()" (blur)="hideTooltip()">i</div></div></th>
        <th class="table-header direct-billing-header direct-billing-cell-normal"><div class="tooltip-header">Provider No.<div id="direct-provider-anchor" class="default-tooltip no-select direct-billing-tooltip" (mouseenter)="showTooltipProvider()" (focus)="showTooltipProvider()" (mouseleave)="hideTooltipProvider()" (blur)="hideTooltipProvider()">i</div></div></th>
        <th class="table-header direct-billing-header direct-billing-cell-normal">Item No.</th>
        <th class="table-header direct-billing-header direct-billing-cell-normal">Fee</th>
        <th class="table-header direct-billing-header direct-billing-cell-small">Delete</th>
      </tr>
      </thead>
      <tbody [id]="directBillingUuid">
      <tr *ngFor="let item of items; let i = index">
        <td class="direct-billing-row direct-billing-cell direct-billing-cell-right-border" [ngClass]="{'default-cell-error': this.errors[i][0]}">
          <input class="direct-billing-input" placeholder="Invoice No." [ngModel]="item.invoiceNum" (ngModelChange)="item.invoiceNum = $event" [ngModelOptions]="{updateOn:'blur'}" (blur)="verifyInvoiceNum(item.invoiceNum, i)" maxlength="20">
        </td>
        <td class="direct-billing-row direct-billing-cell direct-billing-cell-right-border" [ngClass]="{'default-cell-error': this.errors[i][1]}">
          <input class="direct-billing-input direct-billing-date" type="date" [ngModel] ="item.date | date:'yyyy-MM-dd'" (ngModelChange)="item.date = $event" [min]="minDate" [max]="maxDate"  [ngModelOptions]="{updateOn:'blur'}" (blur)="verifyDateBlur(i)">
        </td>
        <td class="direct-billing-row direct-billing-cell-normal direct-billing-cell direct-billing-cell-right-border" [ngClass]="{'default-cell-error': this.errors[i][2]}"><input class="direct-billing-input" placeholder="Membership No." [(ngModel)]="item.membershipNum" maxlength="9" (keydown)="utils.membershipNumberKeyDown($event)" (blur)="verifyMembership(i)"></td>
        <td class="direct-billing-row direct-billing-cell-small direct-billing-cell direct-billing-cell-right-border" [ngClass]="{'default-cell-error': this.errors[i][3]}"><input class="direct-billing-input" placeholder="IRN" [(ngModel)]="item.irn" maxlength="2" (keydown)="utils.irnKeyDown($event)" (blur)="verifyIrn(item.irn, i)"></td>
        <td class="direct-billing-row direct-billing-cell-normal direct-billing-cell direct-billing-cell-right-border" [ngClass]="{'default-cell-error': this.errors[i][4]}"><input class="direct-billing-input" placeholder="Provider No." [(ngModel)]="item.providerNum" maxlength="9" (blur)="verifyProvider(i)"></td>
        <td class="direct-billing-row direct-billing-cell-normal direct-billing-cell direct-billing-cell-right-border" [ngClass]="{'default-cell-error': this.errors[i][5]}"><input class="direct-billing-input" placeholder="Item No." [(ngModel)]="item.itemNum" maxlength="6" (keydown)="utils.itemNumKeyDown($event)" (blur)="verifyItem(i)"></td>
        <td class="direct-billing-row direct-billing-cell-normal direct-billing-cell direct-billing-cell-right-border" [ngClass]="{'default-cell-error': this.errors[i][6]}"><input class="direct-billing-input" placeholder="$0.00" [(ngModel)]="item.fee" [ngModelOptions]="{updateOn:'blur'}" (ngModelChange)="currencyConverter($event, item, i)" (keydown)="utils.feeKeyDown($event)" maxlength="12"></td>
        <td class="direct-billing-row direct-billing-cell-small direct-billing-delete direct-billing-cell" (click)="removeItem(i)"><div class="direct-billing-control-container"><div class="default-delete-icon"><img src="../../../assets/Iconography/CrossWhite.svg"></div></div></td>
      </tr>
      </tbody>
      <tfoot>
      <tr>
        <td class="direct-billing-row direct-billing-cell-normal"></td>
        <td class="direct-billing-row direct-billing-cell-normal"></td>
        <td class="direct-billing-row direct-billing-cell-normal"></td>
        <td class="direct-billing-row direct-billing-cell-small"></td>
        <td class="direct-billing-row direct-billing-cell-normal"></td>
        <td class="direct-billing-row direct-billing-cell-normal direct-billing-total-text">Sub total:</td>
        <td class="direct-billing-row direct-billing-cell-normal direct-billing-total-value">{{utils.calculateTotal(this.items) | currency: 'USD'}}</td>
        <td class="direct-billing-row direct-billing-cell-small"></td>
      </tr>
      </tfoot>
    </table>
    <div class="direct-billing-btn-cont" [ngClass]="{'direct-billing-btn-cont-error': !verified}">
      <div *ngIf="!verified" class="direct-billing-error-cont">
        <div *ngIf="errorEmpty" class="default-heading3 direct-billing-error-msg default-small-separator">The highlighted fields are mandatory</div>
        <div *ngIf="errorInvalidCharacter" class="default-heading3 direct-billing-error-msg default-small-separator">The following characters (<span style="font-weight: 900">“</span> <span style="font-weight: 900; margin-left: 5px">@</span> <span style="font-weight: 900; margin-left: 5px">+</span> <span style="font-weight: 900; margin-left: 5px">,</span> <span style="font-weight: 900; margin-left: 5px">'</span> <span style="font-weight: 900; margin-left: 5px">=</span>) are not allowed</div>
        <div *ngIf="errorInvalidCharacterStartsWith" class="default-heading3 direct-billing-error-msg default-small-separator">“-“ not allowed as the first character</div>
        <div *ngIf="errorInvalid" class="default-heading3 direct-billing-error-msg">Please correct invalid field/s</div>
      </div>
      <button *ngIf="!saveLoading" class="default-btn default-btn-primary default-submit-btn" (click)="submit()">Submit</button>
      <button *ngIf="saveLoading" class="default-btn default-btn-primary default-submit-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
          class="loadingRotate"
          version="1.1"
          viewBox="0 0 56 56"
          height="5.292mm"
          width="5.292mm">
          <g
            transform="translate(-55.373511,-106.77655)">
            <path
              d="M 110.06486,141.6844 A 27.153975,26.689692 0 0 1 80.291831,161.23795 27.153975,26.689692 0 0 1 56.682161,134.7766 27.153975,26.689692 0 0 1 80.291831,108.31524 27.153975,26.689692 0 0 1 110.06486,127.8688"
              style="fill:none;fill-opacity:1;stroke:#FFFFFF;stroke-width:2.6173;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:115.842;stroke-opacity:1" />
          </g>
        </svg>
      </button>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<div id="direct-tooltip" class="direct-tooltip" [ngStyle]="{'top': tooltipTop + 'px', 'left': tooltipLeft + 'px'}">
  <img class="direct-tooltip-image" src="../../../assets/Tooltip/Irn-tooltip.png">
</div>
<div id="direct-tooltip-provider" class="provider-tooltip" [ngStyle]="{'top': tooltipTop + 'px', 'left': tooltipLeft + 'px'}">This is the doctors provider number</div>
