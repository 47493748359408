<div class="login-page">
  <div class="login-logo"><img class="logo-img" src="../../../assets/Logo/Logo-67.png" alt="Logo"></div>
  <div class="login-body login-admin-body">
    <div class="default-heading login-header">Login to admin eligibility tool</div>
    <div>
      <button *ngIf="!loading" class="default-btn default-btn-primary login-btn login-separator" (click)="login()">Login</button>
      <button *ngIf="loading" class="default-btn default-btn-primary login-btn login-separator">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
          class="loadingRotate"
          version="1.1"
          viewBox="0 0 56 56"
          height="5.292mm"
          width="5.292mm">
          <g
            transform="translate(-55.373511,-106.77655)">
            <path
              d="M 110.06486,141.6844 A 27.153975,26.689692 0 0 1 80.291831,161.23795 27.153975,26.689692 0 0 1 56.682161,134.7766 27.153975,26.689692 0 0 1 80.291831,108.31524 27.153975,26.689692 0 0 1 110.06486,127.8688"
              style="fill:none;fill-opacity:1;stroke:#FFFFFF;stroke-width:2.6173;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:115.842;stroke-opacity:1" />
          </g>
        </svg>
      </button>


    </div>
    <app-footer></app-footer>
  </div>
</div>







