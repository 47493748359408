import { Component, OnInit } from '@angular/core';
import {BroadcastService, MsalService} from "@azure/msal-angular";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-login-admin',
  templateUrl: './login-admin.component.html',
  styleUrls: ['./login-admin.component.scss']
})
export class LoginAdminComponent implements OnInit {

  public loading = false;

  public loginPressed = false;

  constructor(
    private broadcastService: BroadcastService,
    private authService: MsalService,
    public router: Router,
    public auth: AuthService) {
    this.auth.admin = true;
  }

  ngOnInit(): void {
    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.auth.admin = true;
      if (this.loginPressed){
        this.router.navigate(["/admin/remittance"]);
      }
    });

    this.broadcastService.subscribe('msal:loginFailure', () => {
      this.loading = false;
    });
  }

  /**
   * Login with microsoft MSAL library
   */
  login() {
    this.loginPressed = true;
    if (!this.loading){
      this.loading = true;
      const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
      if (isIE) {
        this.authService.loginRedirect({
          extraScopesToConsent: ["user.read"]
        });
      } else {
        this.authService.loginPopup({
          extraScopesToConsent: ["user.read"]
        });
      }
    }

  }

}
