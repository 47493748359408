<div class="footer-container" [ngClass]="{'footer-container-user': !authService.admin}">
  <div class="footer-internal default-page-width">
    <div class="footer-left">
      <div class="footer-top-row">
        <img class="footer-logo" src="../../../assets/Logo/Logo-55.png">
        <div *ngIf="!authService.admin" class="footer-site-map">
          <a class="default-heading2 footer-link no-select" href="{{websiteURL}}">Home</a>
          <div class="footer-separator"></div>
          <a class="default-heading2 footer-link no-select" href="{{websiteURL}}/{{aboutUs}}">About Us</a>
          <div class="footer-separator"></div>
          <a class="default-heading2 footer-link no-select" href="{{websiteURL}}/{{faq}}">FAQs</a>
          <div class="footer-separator"></div>
          <a class="default-heading2 footer-link no-select" href="{{websiteURL}}/{{oshc_home}}">OSHC</a>
          <div class="footer-separator"></div>
          <a class="default-heading2 footer-link no-select" href="{{websiteURL}}/{{ovhc_home}}">OVHC</a>
          <div class="footer-separator"></div>
          <a class="default-heading2 footer-link no-select" href="{{websiteURL}}/{{contactUs}}">Contact Us</a>
        </div>
      </div>
      <div *ngIf="!authService.admin" class="have-a-question-small">
        <p class="default-heading2 footer-login-text">Have a question? Our team is here to help.<br><br>Phone - <a class="footer-link" href="tel:{{providerRelationTel}}">{{providerRelationTel}}</a> {{officeHrs}}</p>
      </div>
      <div class="footer-bottom-row">
        <a *ngIf="!authService.admin" class="default-heading2 footer-link no-select" href="{{websiteURL}}/{{privacyURL}}">Privacy policy</a>
        <div *ngIf="!authService.admin" class="footer-separator">|</div>
        <a *ngIf="!authService.admin" class="default-heading2 footer-link no-select" href="{{websiteURL}}/{{termsOfUseURL}}">Terms of Use</a>
        <div *ngIf="!authService.admin" class="footer-separator copyright-big">|</div>
        <div *ngIf="!authService.admin" class="default-heading3 footer-copyright copyright-big">Copyright &copy; 2024 CBHS International Health is a business of CBHS Corporate Health Pty Ltd ABN 85 609 980 896</div>
      </div>
      <div *ngIf="!authService.admin" class="copyright-small">
        <div *ngIf="!authService.admin" class="default-heading3 footer-copyright">Copyright &copy; 2024 CBHS International Health is a business of CBHS Corporate Health Pty Ltd ABN 85 609 980 896</div>
      </div>
    </div>
    <div *ngIf="!authService.admin" class="footer-right">
      <p class="default-heading2 footer-login-text">Have a question? Our team is here to help.<br><br>Phone - <a class="footer-link" href="tel:{{providerRelationTel}}">{{providerRelationTel}}</a> {{officeHrs}}</p>
    </div>
  </div>
</div>
