import { BrowserModule } from '@angular/platform-browser';
import { NgModule  } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { EligibilityCheckComponent } from './components/eligibility-check/eligibility-check.component';
import { InvoiceSubmissionComponent } from './components/invoice-submission/invoice-submission.component';
import {PropertiesService} from "./services/properties.service";
import {MediaService} from "./services/media.service";
import {UtilitiesService} from "./services/utilities.service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { HttpClientModule} from "@angular/common/http";
import {FormsModule} from "@angular/forms";
import { DirectBillingComponent } from './components/direct-billing/direct-billing.component';
import { ClientRemittanceComponent } from './components/client-remittance/client-remittance.component';
import {CurrencyPipe, DatePipe} from "@angular/common";
import { RemittanceManagerComponent } from './components/remittance-manager/remittance-manager.component';
import {NavService} from "./services/nav.service";
import {AlertService} from "./services/alert.service";
import { SharedModule } from './modules/shared/shared.module';

import {EligibilityService} from "./services/eligibility.service";
import { SettingsComponent } from './components/settings/settings.component';
import { MsalApplicationModule } from './modules/msal-application.module';



const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const hostname = window.location.host;
const protocol = window.location.protocol;
const domain = protocol + "//" + hostname;



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    EligibilityCheckComponent,
    InvoiceSubmissionComponent,
    DirectBillingComponent,
    ClientRemittanceComponent,
    RemittanceManagerComponent,
    SettingsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    SharedModule,
    AppRoutingModule,
    MsalApplicationModule.forRoot()
  ],
  providers: [
      PropertiesService,
    MediaService,
    UtilitiesService,
    EligibilityService,
    CurrencyPipe,
    DatePipe,
    NavService,
    AlertService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
