import {Component, HostListener} from '@angular/core';
import {AuthService} from "./services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'cbhs-portal-client';

  @HostListener('window:mousemove', ['$event'])
  mousemove() {
    this.auth.activity = true;
  }

  @HostListener('window:mousedown', ['$event'])
  mousedown() {
    this.auth.activity = true;
  }

  @HostListener('window:keydown', ['$event'])
  keydown() {
    this.auth.activity = true;
  }

  constructor(public auth: AuthService) { }

  ngOnInit() {
  }

}
