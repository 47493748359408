import { Injectable } from '@angular/core';
import {PropertiesService} from "./properties.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {Cookie} from "ng2-cookies/ng2-cookies";

@Injectable({
  providedIn: 'root'
})
export class StateService {

  public state: any;

  constructor(
    public prop: PropertiesService,
    private http: HttpClient,
    public auth: AuthService) { }

  /**
   * Get the state of for a provider user
   *
   * @private
   */
  private getStateProvider(){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.stateApi;
    return this.http.get(uri,{headers: headers, responseType: "json"});
  }

  /**
   * Get the state of an admin user
   *
   * @private
   */
  private getStateAdmin(){
    let headers = new HttpHeaders();
    let uri = this.prop.stateApi + "/admin";
    return this.http.get(uri,{headers: headers, responseType: "json"});
  }

  /**
   * General get method where data will be marshalled depending on if the user is an admin or a provider.
   */
  public get(){
    if (this.auth.admin){
      return this.getStateAdmin();
    } else {
      return this.getStateProvider();
    }
  }


}
