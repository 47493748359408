import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {InvoiceSubmissionComponent} from "./components/invoice-submission/invoice-submission.component";
import {DirectBillingComponent} from "./components/direct-billing/direct-billing.component";
import {RemittanceManagerComponent} from "./components/remittance-manager/remittance-manager.component";
import {ClientRemittanceComponent} from "./components/client-remittance/client-remittance.component";
import {EligibilityCheckComponent} from "./components/eligibility-check/eligibility-check.component";
import {LoginComponent} from "./components/login/login.component";
import { AdminModule } from './modules/admin/admin.module';
import {SettingsComponent} from "./components/settings/settings.component";


const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'eligibility', component: EligibilityCheckComponent},
  {path: 'invoice', component: InvoiceSubmissionComponent},
  {path: 'direct', component: DirectBillingComponent},
  {path: 'remittance', component: RemittanceManagerComponent},
  {path: 'settings', component: SettingsComponent},
  {path: 'remittance/claim', component: ClientRemittanceComponent},
  {path: 'admin', loadChildren: () => AdminModule}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
