import { Component, OnInit } from '@angular/core';
import {MediaService} from "../../services/media.service";
import {HttpEventType, HttpResponse} from "@angular/common/http";
import {AuthService} from "../../services/auth.service";
import * as uuid from 'uuid';
import {ClaimsService} from "../../services/claims.service";
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from "angular-animations";
import {NavService} from "../../services/nav.service";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-invoice-submission',
  templateUrl: './invoice-submission.component.html',
  styleUrls: ['./invoice-submission.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 300}),
    fadeOutOnLeaveAnimation({ duration: 300})
  ]
})
export class InvoiceSubmissionComponent implements OnInit {

  public submissionUuid = uuid.v4();

  public files: Array<File> = [

    ];

  public loading = [];

  public processedMedia: any = [];

  public focus = -1;

  public invoiceView = false;

  public saveLoading = false;

  constructor(
    public mediaService: MediaService,
    public auth: AuthService,
    public claimsService: ClaimsService,
    public navService: NavService,
    public alert: AlertService) {
    this.navService.navTab = 2;
    this.auth.get();
    this.auth.verifyActivityStatus();
  }

  ngOnInit(): void {
  }


  /**
   * Check the file type for a blocked type and return boolean indicating if te file is blocked or not.
   * Allowable file types include: jpeg, png, bmp and pdf
   *
   * @param file
   * @private boolean indicating file status
   */
  private blockedFileTypes(file: File){
    var contentType = file.type;
    var jpeg = contentType == "image/jpeg";
    var png = contentType == "image/png";
    var bmp = contentType == "image/bmp";
    var image = jpeg || png || bmp;
    return !(image || contentType == "application/pdf");
  }

  /**
   * Processes files dropped in the acceptable window by the user. This function will check the compatibility of the file
   * before submitting the file to the server to be uploaded to the datastore.
   *
   * @param droppedFiles
   */
  public droppedFiles(droppedFiles: FileList){
    for (var i =0; i < droppedFiles.length; i++){
      var id = uuid.v4();
      if(this.blockedFileTypes(droppedFiles[i])){
        this.files.push(droppedFiles[i]);
        this.loading.push({uuid: id, loading: false, progress: 0, error: true, errormsg: "Format not supported, please try attaching a different file format."});
        this.processedMedia.push(-1);
      } else if (droppedFiles[i].size > 5242880){
        this.files.push(droppedFiles[i]);
        this.loading.push({uuid: id, loading: false, progress: 0, error: true, errormsg: "File is to large please try adding a smaller file."});
        this.processedMedia.push(-1);
      }else {
        this.files.push(droppedFiles[i]);
        this.loading.push({uuid: id, loading: false, progress: 0, error: false, errormsg: ""});
        this.processedMedia.push(-1);
        this.upInvoice(id);
      }
    }
  }

  /**
   * Processes files uploaded to system via the file input button. This function will check the compatibility of the file
   * before submitting the file to the server to be uploaded to the datastore.
   *
   * @param evt
   */
  public upFile(evt){
    let files = evt.target.files;
    var id = uuid.v4();
    for (let file of files){
      if (file.size > 5242880){
        this.files.push(file);
        this.loading.push({uuid: id, loading: false, progress: 0, error: true, errormsg: "Individual file size cannot exceed 5MB"});
        this.processedMedia.push(-1);
      } else if (this.blockedFileTypes(file)){
        this.files.push(file);
        this.loading.push({uuid: id, loading: false, progress: 0, error: true, errormsg: "Format not supported, please try attaching a different file format."});
        this.processedMedia.push(-1);
      } else {
        this.files.push(file);
        this.loading.push({uuid: id, loading: false, progress: 0, error: false, errormsg: ""});
        this.processedMedia.push({id: -1, filename: "", type: "", userId: -1});
        this.upInvoice(id);
      }
    }
  }

  /**
   * Find the index of the file being uploaded by the files uuid.
   *
   * @param id
   */
  public findByUuid(id){
    for (var i=0; i < this.loading.length; i++){
      if (this.loading[i].uuid == id){
        return i;
      }
    }
    return -1;
  }

  /**
   * Process the event that occurs after after an invoice has been uploaded to a the server.
   *
   * @param event
   * @param uuid
   * @private
   */
  private processUploadedInvoice(event, uuid){
    var pos = this.findByUuid(uuid);
    if (event.type === HttpEventType.UploadProgress) {
      this.loading[pos].progress = Math.min(99, Math.round(100 * event.loaded / event.total));
    } else if (event instanceof HttpResponse) {
      var message = event.body;
      this.processedMedia[pos] = message;
      this.loading[pos].loading = false;
    }
  }

  /**
   * Process the error event if an invoice is not able to be uploaded to the server.
   *
   * @param uuid
   * @private
   */
  private processUploadInvoiceError(uuid){
    var pos = this.findByUuid(uuid);
    this.loading[pos].progress = 0;
    this.loading[pos].errormsg = "An unexpected error occurred uploading your file.";
    this.loading[pos].error = true;
    this.loading[pos].loading = false;
    this.processedMedia[pos] = -1;
  }

  /**
   * Upload an invoice to the server where the file will be stored.
   *
   * @param uuid
   */
  public upInvoice(uuid){
    var pos = this.findByUuid(uuid);
    this.loading[pos].loading = true;
    var file = this.files[pos];
    var formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('userId', this.auth.token.providerNum);
    this.loading[pos].progress = 0;
    this.mediaService.upload(formData)
      .subscribe(
        event => {
          this.processUploadedInvoice(event, uuid);
        },
        err => {
          this.processUploadInvoiceError(uuid);
        });
  }

  /**
   * Remove a file from the memory list. This function is a superset of the remove function as if finds the file by UUID
   *
   * @param id
   */
  public removeFile(id){
    var pos = this.findByUuid(id);
    this.remove(pos);
  }

  /**
   * Remove a file from the memory list given its position within the list.
   *
   * @param pos
   */
  public remove(pos){
    this.files.splice(pos, 1);
    this.loading.splice(pos, 1);
    this.processedMedia.splice(pos, 1);
  }

  /**
   * Delete a file that has been uploaded to the server.
   *
   * @param pos
   */
  public delete(pos){
    var id = this.loading[pos].uuid;
    if (this.processedMedia[pos] != -1 && this.files[pos] !== null){
      this.mediaService.delete(this.processedMedia[pos].id)
        .subscribe(
          data => {
            this.removeFile(id);
          },
          err => {
            this.removeFile(id);
          }
        );
    } else {
      this.removeFile(id);
    }
  }

  /**
   * Save the media files to the invoice. This function will be called after the submit invoice function.
   *
   * @param processedFiles
   * @param data
   * @private
   */
  private updateMediaFiles(processedFiles, data){
    data.claimFiles = processedFiles;
    this.claimsService.saveClaimFiles(data)
    .subscribe(
      data => {
        this.saveLoading = false;
        this.files = [];
        this.loading = [];
        this.processedMedia = [];
        this.alert.success("Success, your file(s) have been submitted");
      },
      err => {
        this.saveLoading = false;
        this.alert.error("Your file(s) could not be submitted please try again");
      });
  }

  /**
   * Returns a JSON object containing a list of successfully processed files and booleans indicating if files are still
   * being processed or have caused error.
   *
    * @private JSON object
   */
  private getProcessedFiles(){
    var processedFiles = [];
    var loading = false;
    var error = false;
    for (var i=0; i < this.processedMedia.length; i++){
      loading = this.loading[i].loading || loading;
      if (this.processedMedia[i].id >= 0){
        processedFiles.push(this.processedMedia[i])
      } else {
        error = true;
      }
    }
    return {
      processedFiles: processedFiles,
      loading: loading,
      error: error
    }
  }

  /**
   * This function will be initiated after a user clicks submit invoice. The function will initiate the submission sequence
   * with the backend.
   *
   * @param processedFiles
   * @private
   */
  private submitInvoice(processedFiles){
    this.claimsService.submitInvoice([], this.auth.token.providerNum, this.auth.token.providerName)
      .subscribe(
        data => {
          this.updateMediaFiles(processedFiles, data);
        },
        err => {
          this.saveLoading = false;
          this.alert.error("Your file(s) could not be submitted please try again");
        }
      );
  }

  /**
   * This function will check if the interface is in a valid state before calling the function to initiate the submission
   * sequence
   *
   */
  public submit(){
    this.saveLoading = true;
    var results = this.getProcessedFiles();
    var processedFiles = results.processedFiles;
    var loading = results.loading;
    var error = results.error;
    if (!loading && !error && processedFiles.length > 0){
      this.submitInvoice(processedFiles)
    } else if (processedFiles.length == 0) {
      this.saveLoading = false;
      this.alert.error("Please attach a receipt")
    } else if (error) {
      this.saveLoading = false;
      this.alert.error("Please remove all invalid files before submission")
    } else {
      this.saveLoading = false;
    }
  }

  /**
   * Opens the invoice modal window
   */
  public openInvoice(){
    this.invoiceView = true;
  }

  /**
   * Closes the invoice modal window
   */
  public closeInvoice(){
    this.invoiceView = false;
  }

  /**
   * Opens the alert window to show the user has not submitted any invoices.
   */
  public clickEmpty(){
    this.alert.error("Please attach a receipt")
  }

  /**
   * Function is initiated when a user clicks a previously uploaded invoice.
   *
   * @param pos
   */
  public clickInvoice(pos){
    if (this.processedMedia[pos].id != -1 &&
      this.loading[pos].loading == false &&
      this.loading[pos].error == false){
      this.focus = pos;
      this.openInvoice();
    }
  }

  /**
   * Clears all the invoices that have been uploaded.
   */
  public clear(){
    for (let i=0; i < this.processedMedia.length; i++){
      if (this.processedMedia[i] != -1 && this.files[i] !== null) {
        this.mediaService.delete(this.processedMedia[i].id).subscribe(data => {}, err => {});
      }
    }
    this.files = [];
    this.processedMedia = [];
    this.loading = [];
  }



}
