import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';

@Injectable()
export class ConfigurationService {

  private configuration: IServerConfiguration;
  private http: HttpClient;
  constructor(private readonly httpHandler: HttpBackend) { 
    this.http = new HttpClient(httpHandler);
  }

  loadConfig() {
    return this.http.get<IServerConfiguration>('/api/Configuration/ConfigurationData')
      .toPromise()
      .then(result => {
        this.configuration = <IServerConfiguration>(result);
      }, error => console.error(error));
  }

  get FormUrl() {
    return this.configuration.formUrl;
  }
  get ProviderEmail() {
    return this.configuration.providerEmail;
  }
  get ProviderTel() {
    return this.configuration.providerTel;
  }
  get ProviderHelperURL() {
    return this.configuration.providerHelperURL;
  }
  get ProviderRelationEmail() {
    return this.configuration.providerRelationEmail;
  }
  get ProviderRelationTel() {
    return this.configuration.providerRelationTel;
  }
  get WebsiteURL() {
    return this.configuration.websiteURL;
  }
  get AboutUs() {
    return this.configuration.aboutUs;
  }
  get FAQ() {
    return this.configuration.faq;
  }
  get OSHC_home() {
    return this.configuration.oshC_home;
  }
  get OVHC_home() {
    return this.configuration.ovhC_home;
  }
  get ContactUs() {
    return this.configuration.contactUs;
  }
  get PrivacyURL() {
    return this.configuration.privacyURL;
  }
  get TermsOfUseURL() {
    return this.configuration.termsOfUseURL;
  }get OfficeHrs() {
    return this.configuration.officeHrs;
  }get Scope() {
    return this.configuration.adClient.scope;
  }get FrontendClientId() {
    return this.configuration.adClient.frontendClientId;
  }get redirectUri() {
    return this.configuration.adClient.redirectUri;
  }get authority() {
    return this.configuration.adClient.authority;
  }get cacheLocation() {
    return this.configuration.adClient.cacheLocation;
  }
}

export interface IServerConfiguration {
  formUrl: string;
  providerEmail: string;
  providerTel: string;
  providerHelperURL: string;
  providerRelationEmail: string;
  providerRelationTel: string;
  websiteURL: string;
  aboutUs: string;
  faq: string;
  oshC_home: string;
  ovhC_home: string;
  contactUs: string;
  privacyURL: string;
  termsOfUseURL: string;
  officeHrs: string;
  adClient:{
    scope: string;
    frontendClientId: string;
    redirectUri: string;
    authority: string;
    cacheLocation: string;
  }
}