import {Component, OnDestroy, OnInit} from '@angular/core';
import {ClaimsService} from "../../services/claims.service";
import {UtilitiesService} from "../../services/utilities.service";
import {Router} from "@angular/router";
import {NavService} from "../../services/nav.service";
import {AuthService} from "../../services/auth.service";
import {AlertService} from "../../services/alert.service";
import {StateService} from "../../services/state.service";
import {interval} from "rxjs";

import {
  fadeInOnEnterAnimation,
  fadeInRightOnEnterAnimation, fadeOutOnLeaveAnimation,
  fadeOutRightOnLeaveAnimation
} from "angular-animations";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-remittance-manager-admin',
  templateUrl: './remittance-manager-admin.component.html',
  styleUrls: ['./remittance-manager-admin.component.scss'],
  animations: [
    fadeInRightOnEnterAnimation({ duration: 300}),
    fadeOutRightOnLeaveAnimation({ duration: 300}),
    fadeInOnEnterAnimation({ duration: 300}),
    fadeOutOnLeaveAnimation({ duration: 300})
  ]
})
export class RemittanceManagerAdminComponent implements OnInit, OnDestroy {

  public refreshTrigger: any;

  public pageNumbers = [];

  public refresh = false;

  public refreshIgnore = false;

  public reportDateStart = null;

  public reportDateEnd = null;

  public reportGenerationError = false;

  public reportGenerationErrorMessage = "";

  public loadingReport = false;

  public reportModal = false;

  public savingState = [];

  public downloadState = [];

  public loadingClaims = false;

  public maxDate;

  public minDate = "0001-01-02";

  constructor(
    public claimsService: ClaimsService,
    public utils: UtilitiesService,
    public router: Router,
    public navService: NavService,
    public auth: AuthService,
    public alert: AlertService,
    public stateService: StateService,
    public datePipe: DatePipe) {
    this.auth.verifyAdmin();
    this.navService.navTab = 4;
    this.auth.admin = true;
    this.getClaims();
    this.countClaims();
    this.setState();
    this.savingState = [];
    for (var i=0; i < this.claimsService.size; i++){
      this.savingState.push(false);
      this.downloadState.push(false);
    }
    this.maxDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit(): void {

  }

  /**
   * Set the state of this session
   */
  public setState(){
    this.stateService.get()
      .subscribe(
        data =>{
          this.stateService.state = data;
          this.intervalState();
        }, err =>{
          // this.auth.verifyAdmin();
        }
      )
  }

  /**
   * Continually poll the state to see if new claims have been submitted
   *
   */
  public intervalState(){
    this.refreshTrigger = interval(3000)
      .subscribe(() => {
        this.stateService.get()
          .subscribe(
            data => {
              this.verifyState(data);
            }, err => {
              this.auth.verifyAdmin();
              this.refreshTrigger.unsubscribe();
            }
          )
      });
  }

  /**
   * Verify if the state has changed
   *
   * @param data
   */
  public verifyState(data){
    if (this.stateService.state.state != data.state && !this.refreshIgnore){
      this.refresh = true;
      this.stateService.state = data;
    } else if (this.stateService.state.state != data.state && this.refreshIgnore){
      this.refreshIgnore = false;
      this.stateService.state = data;
    }
  }

  /**
   * Get the display start for the pagination of the table
   *
   */
  public getDisplayStart(){
    return ((this.claimsService.page - 1) * this.claimsService.size) + 1;
  }

  /**
   * Get the display end for the pagination of the of the table
   *
   */
  public getDisplayEnd(){
    var pageSize = ((this.claimsService.page - 1) * this.claimsService.size) + this.claimsService.remittance.length;
    var maxSize = ((this.claimsService.page - 1) * this.claimsService.size) + this.claimsService.size;
    return Math.min(pageSize, maxSize);
  }

  /**
   * Click a new page number
   *
   * @param p
   */
  public clickPage(p){
    this.claimsService.page = p;
    this.pageNumbers = this.utils.calculatePageNumbers(this.claimsService.page, this.claimsService.size, this.claimsService.count);
    this.countClaims();
    this.getClaims();
  }

  /**
   * Click a claim item within the display list. This function retrieves the whole object from the server and redirects
   * the user to a new page.
   *
   * @param item
   */
  public clickItem(item){
    this.claimsService.getClaimAdmin(item.id)
      .subscribe(
        data => {
          this.claimsService.claim = data;
          this.router.navigate(["/admin/remittance/claim"])
        }, err => {
          this.auth.verifyUser();
          try{
            this.refreshTrigger.unsubscribe();
          } catch (e){}
        }
      )

  }

  /**
   * Count the total number of claims with the current filter settings applied
   */
  public countClaims(){
    this.claimsService.countClaimsAdmin(this.getFilterFrom(), this.getFilterTo(), this.claimsService.filterType, this.claimsService.filterStatus)
      .subscribe(
        data => {
          this.claimsService.count = +data;
          this.pageNumbers = this.utils.calculatePageNumbers(this.claimsService.page, this.claimsService.size, this.claimsService.count);
        }, err => {
          this.auth.verifyUser();
          try{
            this.refreshTrigger.unsubscribe();
          } catch (e){}
        }
      )
  }

  /**
   * Get all of the claims with current filter settings applied
   */
  public getClaims(){
    this.loadingClaims = true;
    this.claimsService.getClaimsAdmin(this.getFilterFrom(), this.getFilterTo(), this.claimsService.filterType, this.claimsService.filterStatus)
      .subscribe(
        data => {
          this.claimsService.remittance = data;
          this.loadingClaims = false;
        }, err => {
          this.loadingClaims = false;
          try{
            this.refreshTrigger.unsubscribe();
          } catch (e){}
          this.auth.verifyUser();
        }
      )
  }

  /**
   * Process a change to the search query
   */
  public search(){
    this.filterChange();
  }

  ngOnDestroy(): void {
    try{
      this.refreshTrigger.unsubscribe();
    } catch (e){}
  }

  /**
   * Refresh the display list after there has been a known change to the state
   *
   */
  public clickRefresh(){
    this.refresh = false;
    this.getClaims();
    this.countClaims();
  }

  /**
   * Generate the start date of the report.
   *
   * @private
   */
  private getReportDateStart(){
    var minDate = this.getStartDate(this.reportDateStart);
    minDate.setSeconds(0);
    return minDate;
  }

  /**
   * Generate the end date for the report
   *
   * @private
   */
  private getReportDateEnd(){
    var maxDate = this.getEndDate(this.reportDateEnd);
    maxDate.setTime(maxDate.getTime() + 1000 * 60);
    maxDate.setSeconds(0);
    return maxDate;
  }

  /**
   * Generate the general start date
   *
   * @param d
   */
  public getStartDate(d){
    if (d == null || d == ""){
      return new Date("0001-01-02");
    } else {
      var startDate = new Date(d);
      return startDate;
    }
  }

  /**
   * Generate the generic end date.
   *
   * @param d
   */
  public getEndDate(d){
    if (d == null || d == ""){
      return new Date();
    } else {
      var endDate = new Date(d);
      return endDate;
    }
  }

  /**
   * Validate that the report dates are within the allowable range.
   *
   * @private
   */
  private validateReportDate(){
    var rdStart = this.getStartDate(this.reportDateStart);
    var rdEnd = this.getEndDate(this.reportDateEnd);
    return rdStart <= rdEnd;
  }

  /**
   * Validate that the report date is not before the earliest allowable time or any time in the future.
   *
   * @private
   */
  private validateReportDatePastFuture(){
    var rdStart = this.getStartDate(this.reportDateStart);
    var rdEnd = this.getEndDate(this.reportDateEnd);
    var current = new Date();
    var earliest = new Date(this.minDate);
    return rdStart <= current && rdEnd <= current && rdStart >= earliest && rdEnd >= earliest;
  }

  /**
   * Request the report data from the server.
   *
   * @private
   */
  private getReport(){
    this.loadingReport = true;
    this.claimsService.generateReport(this.getReportDateStart().toISOString(), this.getReportDateEnd().toISOString())
      .subscribe(
        data =>{
          this.loadingReport = false;
          this.utils.downloadCSVGroup(data, this.reportDateStart, this.reportDateEnd);
          this.closeReport();
        }, err =>{
          this.loadingReport = false;
          this.alert.error("An error occurred generating you report please try again")
        }
      )
  }

  /**
   * Generate the report data
   *
   */
  public generateReport(){
     if (this.validateReportDates()) {
      this.getReport();
    }
  }

  /**
   * Validate the report dates
   *
   */
  public validateReportDates(){
    this.reportGenerationError = false;
    var noData = this.reportDateStart == null || this.reportDateStart == "" ||
      this.reportDateEnd == null || this.reportDateEnd == "";
    if (!this.validateReportDate() && !noData){
      this.reportGenerationError = true;
      this.reportGenerationErrorMessage = "Invalid date/time";
      return false;
    } else if (!this.validateReportDatePastFuture()){
      this.reportGenerationError = true;
      this.reportGenerationErrorMessage = "Invalid date/time";
      return false;
    }
    return true;
  }

  /**
   * Generate the filter start / from date.
   *
   * @private
   */
  private getFilterFrom(){
    var minDate = this.getStartDate(this.claimsService.filterDateStart);
    minDate.setSeconds(0);
    return minDate.toISOString();
  }

  /**
   * Generate the filter end / to date.
   *
   * @private
   */
  private getFilterTo(){
    var maxDate = this.getEndDate(this.claimsService.filterDateEnd);
    maxDate.setTime(maxDate.getTime() + 1000 * 60);
    maxDate.setSeconds(0);
    return maxDate.toISOString();
  }

  /**
   * Open the generate report modal
   */
  public openReport(){
    var today = new Date();
    this.reportDateStart = today.setHours(0, 0, 0, 0);
    this.reportDateEnd = new Date();
    this.reportModal = true;
  }

  /**
   * Close the generate report modal
   */
  public closeReport(){
    this.reportModal = false;
  }

  /**
   * Download all the claim files associated with a specific claim.
   *
   * @param claim
   * @private
   */
  private downloadAllCf(claim, pos){
    if (claim.claimFiles.length >= 10){
      this.downloadState[pos] = true;
      this.utils.downloadZipBlank(claim.id, claim.providerName)
        .subscribe(
          data => {
            this.downloadState[pos] = false;
            this.utils.downloadBlob(data, claim.providerName + ".zip");
          }, err => {
            this.downloadState[pos] = false;
            this.alert.error("An error occurred downloading your zip file");
          }
        );
    } else {
      this.downloadState[pos] = false;
      for (let cf of claim.claimFiles){
        this.utils.download(cf);
      }
    }
  }

  /**
   * Download the relevant data associated with a specific claim. If this is a direct bill a CSV file wil be downloaded.
   * If this is a invoice all the claim files will be downloaded.
   *
   * @param claim
   * @param pos
   */
  public download(claim, pos){
    if (!this.downloadState[pos]){
      this.downloadState[pos] = true;
      if (claim.type == "direct"){
        this.utils.downloadCsv(claim);
        this.downloadState[pos] = false;
      } else {
        this.downloadAllCf(claim, pos);
      }
    }
  }

  /**
   * Save the claim located in the current position.
   *
   * @param claim
   * @param pos
   */
  public saveClaim(claim, pos){
    this.claimsService.saveClaimAdmin(claim)
      .subscribe(
        data => {
          this.refreshIgnore = true;
          this.changeSaveState(pos);
        },
        err => {
          this.alert.error("An error occurred saving your file");
        }
      );
  }

  /**
   * Change the save state associated with a specific claim. This is used to provide feedback to the user.
   *
   * @param pos
   */
  public changeSaveState(pos){
    this.savingState[pos] = true;
    var saveInterval = interval(3500)
      .subscribe(() => {
        this.savingState[pos] = false;
        saveInterval.unsubscribe();
      });
  }

  /**
   * Clear all the filters
   *
   */
  public clearFilters(){
    this.claimsService.filterDateStart = null;
    this.claimsService.filterDateEnd = null;
    this.claimsService.filterType = "any";
    this.claimsService.filterStatus = "any";
    this.filterChange();
  }

  /**
   * Process a change to the filter state.
   *
   */
  public filterChange(){
    this.claimsService.filterError = false;
    if (this.verifyFilterDates()){
      this.claimsService.page = 1;
      this.getClaims();
      this.countClaims();
    } else {
    }

  }

  /**
   * Validate the to and from dates for the filters
   */
  public validateToFrom(){
    try{
      this.claimsService.filterError = false;
      var noData = this.claimsService.filterDateStart == null || this.claimsService.filterDateStart == "" ||
        this.claimsService.filterDateEnd == null || this.claimsService.filterDateEnd == "";
      var sd = this.getStartDate(this.claimsService.filterDateStart);
      var ed = this.getEndDate(this.claimsService.filterDateEnd);
      var validation = sd.getTime() <= ed.getTime();
      this.claimsService.filterError = !validation && !noData;
      return validation;
    } catch (e){
      this.claimsService.filterError = true;
      return true;
    }
  }

  /**
   * Validate the dates do not occur in the future or before the earliest specified date.
   */
  public validatePastFutureFilter(){
    this.claimsService.filterPastFutureError = false;
    var ed = this.getEndDate(this.claimsService.filterDateEnd);
    var sd = this.getStartDate(this.claimsService.filterDateStart);
    var current = new Date();
    var earliest = new Date(this.minDate);
    var validation = sd <= current && ed <= current && sd >= earliest && ed >= earliest;
    this.claimsService.filterPastFutureError = !validation;
    return validation;
  }

  /**
   * Verify all of the filter dates.
   */
  public verifyFilterDates(){
    var vpff = this.validatePastFutureFilter();
    var vtf = this.validateToFrom();
    return vpff && vtf;
  }

}
