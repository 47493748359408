import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PropertiesService {

  public backend = window.location.protocol + "//" + window.location.host;

  public mediaApi = this.backend + "/api/Media";

  public claimApi = this.backend + "/api/Claim";

  public authApi = this.backend + "/api/Provider";

  public stateApi = this.backend + "/api/State";

  public remittanceApi = this.backend + "/api/Remittance";

  public eligibilityApi = this.backend + "/api/Eligibility";

  public timeoutApi = this.backend + "/api/Timeout";

  public timeoutWarningMinutes = 27;

  constructor() { }
}
