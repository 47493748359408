import { Component, OnInit } from '@angular/core';
import {NavService} from "../../services/nav.service";
import {AuthService} from "../../services/auth.service";
import {AlertService} from "../../services/alert.service";
import {UtilitiesService} from "../../services/utilities.service";
import {TimeoutService} from "../../services/timeout.service";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  public timeout = undefined;

  public timeoutValue = 3;

  public success = false;

  public loading = false;

  constructor(public navService: NavService,
              public auth: AuthService,
              public alert: AlertService,
              public timeoutService: TimeoutService,
              public utils: UtilitiesService) {
    if (this.auth.timeout != undefined){
      this.timeout = this.auth.timeout;
      this.timeoutValue = this.timeout.option;
    }
    this.navService.navTab = 5;
    this.auth.get();
    this.auth.verifyActivityStatus();
    this.get();
  }

  ngOnInit(): void {
  }

  get(){
    this.auth.getTimeoutToken()
      .subscribe(data =>{
        this.timeout = data;
        this.auth.timeout = data;
        this.timeoutValue = this.timeout.option;
      }, err => {
        this.alert.error("An error occurred getting your timeout preference");
      })
  }

  save(){
    this.loading = true;
    this.timeout.option = +this.timeoutValue;
    this.timeoutService.update(this.timeout)
      .subscribe(data => {
        this.loading = false;
        this.alert.success("Timeout setting has been saved")
        this.auth.timeout = data;
      }, error => {
        this.loading = false;
        this.alert.error("An error occurred updating your timeout");
        this.get();
      })
  }

  saveSuccess(){
    this.success = true;
    setTimeout(() => {
      this.success = false;
    }, 2500);
  }

}
