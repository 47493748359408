import { Component, OnInit } from '@angular/core';
import {NavService} from "../../services/nav.service";
import {Router} from "@angular/router";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(public navService: NavService, public router: Router, public auth: AuthService) { }

  ngOnInit(): void {
  }

  /**
   * Navigate depending to a different window depending on the tab that was selected.
   *
   * @param pos
   */
  public clickPos(pos){
    if (pos == 0){
      this.router.navigate(['/eligibility']);
    } else if (pos == 1){
      this.router.navigate(['/direct']);
    } else if (pos == 2){
      this.router.navigate(['/invoice']);
    } else if (pos == 3){
      this.router.navigate(['/remittance']);
    } else if (pos == 4){
      this.router.navigate(['/admin/remittance']);
    } else if (pos == 5){
      this.router.navigate(['/settings']);
    }
  }

}
