<app-navbar></app-navbar>
<div class="default-page-container">
  <div class="default-page-internal footer-spacing">
    <div class="default-heading default-heading-separator">My Settings</div>
    <div class="settings-body-container">
      <div class="default-input-group default-separator">
        <div class="default-heading3 default-small-separator">Portal Timeout</div>
        <select class="default-input settings-select" [(ngModel)]="timeoutValue">
          <option value=0>30 minutes</option>
          <option value=1>1 hour</option>
          <option value=2>2 hours</option>
          <option value=3>4 hours</option>
        </select>
        <div class="settings-btn-group">
          <button *ngIf="!loading && !success" class="default-btn default-btn-primary default-submit-btn" (click)="save()">Save</button>
          <button *ngIf="!loading && success" class="default-btn default-btn-secondary default-submit-btn">
            <svg
              version="1.1"
              viewBox="0 0 35.230999 28.444878"
              height="4.272669mm"
              width="5.292mm">
              <defs>
                <style>.a{fill:#fff;}.b{fill:none;stroke:#ffffff;stroke-linecap:round;stroke-linejoin:round;stroke-width:5px;}</style>
              </defs>
              <path
                class="b"
                d="M 2.5,14.851 13.436,25.945 32.731,2.5"/>
            </svg>

          </button>
          <button *ngIf="loading" class="default-btn default-btn-primary default-submit-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              class="loadingRotate"
              version="1.1"
              viewBox="0 0 56 56"
              height="5.292mm"
              width="5.292mm">
              <g
                transform="translate(-55.373511,-106.77655)">
                <path
                  d="M 110.06486,141.6844 A 27.153975,26.689692 0 0 1 80.291831,161.23795 27.153975,26.689692 0 0 1 56.682161,134.7766 27.153975,26.689692 0 0 1 80.291831,108.31524 27.153975,26.689692 0 0 1 110.06486,127.8688"
                  style="fill:none;fill-opacity:1;stroke:#FFFFFF;stroke-width:2.6173;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:115.842;stroke-opacity:1" />
              </g>
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
