import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminRemittanceComponent } from 'src/app/components/admin-remittance/admin-remittance.component';
import { LoginAdminComponent } from 'src/app/components/login-admin/login-admin.component';
import { RemittanceManagerAdminComponent } from 'src/app/components/remittance-manager-admin/remittance-manager-admin.component';


const routes: Routes = [
  {path: '', component: LoginAdminComponent},
  {path: 'remittance', component: RemittanceManagerAdminComponent},
  {path: 'remittance/claim', component: AdminRemittanceComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
