import { Injectable } from '@angular/core';
import {PropertiesService} from "./properties.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {Cookie} from "ng2-cookies/ng2-cookies";

@Injectable({
  providedIn: 'root'
})
export class EligibilityService {

  constructor(
    public prop: PropertiesService,
    private http: HttpClient,
    public auth: AuthService) { }


  /**
   * Convert the date to ISO string
   *
   * @param d
   * @private
   */
  private convertDate(d: Date){
    try{
      return d.toISOString();
    } catch (e){
      var date = new Date(d);
      return date.toISOString();
    }
  }

  /**
   * Validate a members eligibility. This will validate the result with the backend.
   *
   * @param membershipNumber
   * @param irn
   * @param dateOfBirth
   * @param providerNum
   */
  public check(membershipNumber, irn, dateOfBirth, providerNum){
    let headers = new HttpHeaders()
      .set('Content-type', 'application/json; charset=utf-8')
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.eligibilityApi;
    let params = {
      MembershipNumber: membershipNumber,
      Irn: irn,
      DateOfBirth: this.convertDate(dateOfBirth),
      ClinicId: providerNum
    };
    return this.http.post(uri, params, {headers: headers});
  }

}
