import {Component, EventEmitter, HostListener, OnInit, Output} from '@angular/core';
import * as uuid from 'uuid';
import {UtilitiesService} from "../../services/utilities.service";

@Component({
  selector: 'app-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss']
})
export class FileDropComponent implements OnInit {

  public dropZoneUuid = uuid.v4();

  public dropZoneView = false;

  public dragOver = false;

  public dropZoneActive = false;

  @Output() files = new EventEmitter<FileList>();

  @HostListener('document:dragenter', ['$event'])
  dragEnter(ev) {
    this.dropZoneView = true;
    this.dragOver = true;
  }

  @HostListener('document:dragleave', ['$event'])
  dragLeave(ev) {
    if (!this.dragOver && this.dropZoneView){
      this.dropZoneView = false;
    } else {
      this.dragOver = false;
    }
  }

  @HostListener('body:dragover', ['$event'])
  onDragMove(ev) {
    if (this.dropZoneView){
      var x = ev.clientX;
      var y = ev.clientY;
      var child = <HTMLElement> document.elementFromPoint(x, y);
      var parent = document.getElementById(this.dropZoneUuid);
      var descendant = this.utils.isDescendant(parent, child);
      if (descendant || child.className.indexOf("drop-zone") > -1){
        this.dropZoneActive = true;
      } else {
        this.dropZoneActive = false;
      }
    }
    ev.preventDefault();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    this.dropZoneView = false;
  }

  constructor(public utils: UtilitiesService) {}

  ngOnInit(): void {
    window.addEventListener("drop", ev => {
      this.dropZoneView = false;
      this.dragOver = false;
      ev.preventDefault();
    });
  }

  public dropFile(f){
    var droppedFiles = f.dataTransfer.files;
    f.preventDefault();
    this.files.emit(droppedFiles);
  }

}
