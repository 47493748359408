import {AfterContentInit, AfterViewInit, Component, Input, OnInit} from '@angular/core';
import * as uuid from 'uuid';
import {PropertiesService} from "../../services/properties.service";
import {MediaService} from "../../services/media.service";
import {AlertService} from "../../services/alert.service";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit, AfterContentInit {

  public viewerUUid = uuid.v4();

  @Input() media = {
    "id": -1,
    "filename": "",
    "type": "",
    "userId": ""
  };

  public loading = false;

  constructor(public prop: PropertiesService, public mediaService: MediaService, public alert: AlertService, public auth: AuthService) { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.loading = true;
    this.mediaService.get(this.media.filename)
      .subscribe(
        data => {
          var uri = URL.createObjectURL(data);
          var el = document.getElementById(this.viewerUUid);
          el.setAttribute("src", uri);
          this.loading = false;
        }, err => {
          this.loading = false;
          this.auth.verifyUser();
        }
      );
  }

}
