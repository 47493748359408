import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public modal = false;

  public state = 0;

  public message = "";

  public timeoutValue = 15;

  constructor() { }

  /**
   * Generate success modal window
   *
   * @param message
   */
  public success(message){
    this.modal = true;
    this.state = 0;
    this.message = message;
  }

  /**
   * Generate error message modal
   *
   * @param message
   */
  public error(message){
    this.modal = true;
    this.state = 1;
    this.message = message;
  }

  /**
   * Generate error message modal
   *
   * @param message
   */
  public warning(message){
    this.modal = true;
    this.state = 2;
    this.message = message;
  }

  /**
   * Generate a timeout warning modal
   *
   * @param timeoutValue
   */
  public timeout(timeoutValue){
    this.timeoutValue = timeoutValue;
    this.modal = true;
    this.state = 3;
  }
}
