import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { LoginAdminComponent } from 'src/app/components/login-admin/login-admin.component';
import { AdminRemittanceComponent } from 'src/app/components/admin-remittance/admin-remittance.component';
import { RemittanceManagerAdminComponent } from 'src/app/components/remittance-manager-admin/remittance-manager-admin.component';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    LoginAdminComponent,
    AdminRemittanceComponent,
    RemittanceManagerAdminComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    AdminRoutingModule
  ],
  providers: [
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class AdminModule { }
