import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ClaimsService} from "../../services/claims.service";
import {UtilitiesService} from "../../services/utilities.service";
import {Router} from "@angular/router";
import {NavService} from "../../services/nav.service";
import {AuthService} from "../../services/auth.service";
import {AlertService} from "../../services/alert.service";
import {interval} from "rxjs";
import {StateService} from "../../services/state.service";
import {RemittanceService} from "../../services/remittance.service";
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from "angular-animations";

@Component({
  selector: 'app-remittance-manager',
  templateUrl: './remittance-manager.component.html',
  styleUrls: ['./remittance-manager.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 300}),
    fadeOutOnLeaveAnimation({ duration: 300})
  ]
})
export class RemittanceManagerComponent implements OnInit, OnDestroy {

  public refreshTrigger: any;

  public pageNumbers = [];

  public invoiceView = false;

  public loadingClaims = false;

  constructor(
    public claimsService: ClaimsService,
    public utils: UtilitiesService,
    private router: Router,
    public navService: NavService,
    public auth: AuthService,
    public alert: AlertService,
    public stateService: StateService,
    public remittanceService: RemittanceService) {
    this.navService.navTab = 3;
    this.countClaims();
    this.getClaims();
    this.remittanceService.getPage();
    this.remittanceService.countRemittances();
    this.auth.get();
    this.setState();
    this.auth.verifyActivityStatus();
  }

  /**
   * Destroy the refresh trigger established to check if new claims have been added
   */
  ngOnDestroy(): void {
    try{
      this.refreshTrigger.unsubscribe();
    } catch (e){}
  }

  ngOnInit(): void {
  }

  /**
   * Click a page number (p), retrieve the claims and refresh claim file display data
   *
   * @param p
   */
  public clickPage(p){
    this.claimsService.page = p;
    this.pageNumbers = this.utils.calculatePageNumbers(this.claimsService.page, this.claimsService.size, this.claimsService.count);
    this.countClaims();
    this.getClaims();
  }

  /**
   * Click remittance document page number, retrieve the new claims and refresh the page data.
   *
   * @param p
   */
  public clickPageRemittance(p){
    this.remittanceService.page = p;
    this.pageNumbers = this.utils.calculatePageNumbers(this.remittanceService.page, this.remittanceService.size, this.remittanceService.count);
    this.remittanceService.countRemittances();
    this.remittanceService.getPage();
  }

  /**
   * Count the number of claims available at any time.
   */
  public countClaims(){
    this.claimsService.countClaims()
      .subscribe(
        data => {
          this.claimsService.count = +data;
          this.pageNumbers = this.utils.calculatePageNumbers(this.claimsService.page, this.claimsService.size, this.claimsService.count);
        }, err => {
          this.auth.verifyUser();
        }
      )
  }

  /**
   * Retrieve the data from the backend
   */
  public getClaims(){
    this.loadingClaims = true;
    this.claimsService.getClaims()
      .subscribe(
        data => {
          this.loadingClaims = false;
          this.claimsService.remittance = data;
        }, err => {
          this.loadingClaims = false;
          this.auth.verifyUser();
        }
      )
  }

  /**
   * Return the display start of the numbers in the pagination
   */
  public getDisplayStart(){
    return ((this.claimsService.page - 1) * this.claimsService.size) + 1;
  }

  /**
   * Return the display start of the numbers in the pagination for remittance documents
   */
  public getDisplayStartRemittance(){
    return ((this.remittanceService.page - 1) * this.remittanceService.size) + 1;
  }

  /**
   * Get the display end of the pagination numbers.
   */
  public getDisplayEnd(){
    var pageSize = ((this.claimsService.page - 1) * this.claimsService.size) + this.claimsService.remittance.length;
    var maxSize = ((this.claimsService.page - 1) * this.claimsService.size) + this.claimsService.size;
    return Math.min(pageSize, maxSize);
  }

  /**
   * Get the display end of the pagination numbers associated with remittance documents
   */
  public getDisplayEndRemittance(){
    var pageSize = ((this.remittanceService.page - 1) * this.remittanceService.size) + this.remittanceService.remittances.length;
    var maxSize = ((this.remittanceService.page - 1) * this.remittanceService.size) + this.remittanceService.size;
    return Math.min(pageSize, maxSize);
  }

  /**
   * Get an individual claim item from the backend.
   *
   * @param item
   * @private
   */
  private getClaim(item){
    this.claimsService.getClaim(item.id)
      .subscribe(
        data => {
          this.claimsService.claim = data;
          this.router.navigate(["/remittance/claim"])
        }, err => {
          this.auth.verifyUser();
        }
      )
  }

  /**
   * Get a submitted claim from the backend server
   *
   * @param item
   * @private
   */
  private getSubmittedClaim(item){
    this.claimsService.getClaimSubmitted(item.id)
      .subscribe(
        data => {
          this.claimsService.claim = data;
          this.router.navigate(["/remittance/claim"])
        }, err => {
          this.auth.verifyUser();
        }
      )
  }

  /**
   * Click a claim and open a claim item
   *
   * @param item
   */
  public clickItem(item){
    if (item.state == "approved" || item.state == "rejected"){
      this.getClaim(item);
    } else {
      this.getSubmittedClaim(item);
    }
  }

  /**
   * Set the state
   */
  public setState(){
    this.stateService.get()
      .subscribe(
        data =>{
          this.stateService.state = data;
          this.intervalState();
        }, err =>{
          this.auth.verifyUser();
        }
      )
  }

  /**
   * Subscribe to an event to check if the state of the object has changed.
   */
  public intervalState(){
    this.refreshTrigger = interval(3000)
      .subscribe(() => {
        this.stateService.get()
          .subscribe(
            data => {
              this.verifyState(data);
            }, err => {
              this.auth.verifyUser();
            }
          )
      });
  }

  /**
   * Verify the state object with the recorded state
   *
   * @param data
   */
  public verifyState(data){
    if (this.stateService.state.state != data.state){
      this.getClaims();
      this.countClaims();
      this.stateService.state = data;
    }
  }

  /**
   * Close the invoice
   */
  public closeInvoice(){
    this.invoiceView = false;
  }

  /**
   * Open an invoice at the specified position.
   *
   * @param pos
   */
  public openInvoice(pos){
    this.remittanceService.remittance = this.remittanceService.remittances[pos];
    this.invoiceView = true;
  }



}
