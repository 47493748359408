<div class="login-page">
  <div class="login-logo"><img class="logo-img" src="../../../assets/Logo/Logo-67.png" alt="Logo"></div>
  <div class="login-body" (keyup)="keyUp($event)">
    <div class="default-heading login-header">Login to the CBHS International Choice Network portal</div>
    <div class="login-input-group login-separator">
      <div class="default-heading3 login-small-separator">Provider Number<span *ngIf="providerBlank" class="login-error">Provider Number cannot be blank</span></div>
      <input class="default-input" placeholder="Provider Number" [(ngModel)]="providerNum" maxlength="9" [ngClass]="{'default-input-error': providerBlank}">
    </div>
    <div class="login-input-group login-separator">
      <div class="default-heading3 login-small-separator">Password<span *ngIf="passwordBlank" class="login-error">Password cannot be blank</span></div>
      <input class="default-input login-input" type="password" placeholder="Password" [(ngModel)]="password" maxlength="16" [ngClass]="{'default-input-error': passwordBlank}">
    </div>

    <div *ngIf="auth.error" class="login-error-msg">{{auth.errorMsg}}</div>
    <div>
      <button *ngIf="!auth.loading" class="default-btn default-btn-primary login-btn login-separator" (click)="submit()">Login</button>
      <button *ngIf="auth.loading" class="default-btn default-btn-primary login-btn login-separator">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
          class="loadingRotate"
          version="1.1"
          viewBox="0 0 56 56"
          height="5.292mm"
          width="5.292mm">
          <g
            transform="translate(-55.373511,-106.77655)">
            <path
              d="M 110.06486,141.6844 A 27.153975,26.689692 0 0 1 80.291831,161.23795 27.153975,26.689692 0 0 1 56.682161,134.7766 27.153975,26.689692 0 0 1 80.291831,108.31524 27.153975,26.689692 0 0 1 110.06486,127.8688"
              style="fill:none;fill-opacity:1;stroke:#FFFFFF;stroke-width:2.6173;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:115.842;stroke-opacity:1" />
          </g>
        </svg>
      </button>
      <div class="login-register-heading login-small-separator">Are you looking for hospital eligibility checks?</div>
      <div class="default-paragraph login-separator">
        For Hospital eligibility checks login <b><a href="{{providerHelperURL}}">here</a></b>. Need more info? Email <a href="mailto:{{providerEmail}}">{{providerEmail}}</a> or call {{providerTel}}.
      </div>
    </div>
    <div class="login-register-heading login-small-separator">Are you a General Practice and not registered for the <br>CBHS International Choice Network?</div>
    <div class="default-paragraph login-separator">Complete and return a <a href="{{formURL}}"> CBHS International Choice Network registration form</a>. Terms and conditions can be found at the back of this form. Portal access is available to General Practices who join the CBHS International Choice Network and agree to bill in accordance with our terms and conditions.</div>
    <div class="default-paragraph footer-spacing"><b>Need more info?</b> Call Provider Relations on {{providerRelationTel}} or email <a href="mailto:{{providerRelationEmail}}">{{providerRelationEmail}}</a></div>
    <app-footer [login]="true"></app-footer>
  </div>
</div>
<div *ngIf="termsAndCond" class="default-modal" [@fadeInOnEnter] [@fadeOutOnLeave]>
  <div class="default-modal-backdrop" (click)="closeTerms()"></div>
  <div class="default-modal-card terms-and-conds-modal">
    <div class="default-modal-exit no-select" (click)="closeTerms()"><img class="default-modal-exit-cross" src="../../../assets/Iconography/CrossWhite.svg"></div>
    <div class="terms-and-conds-internal">
      <object data="assets/Pdf/TermsConditions.pdf#toolbar=0&navpanes=0" width="100%" height="100%" type='application/pdf'>
        <p>Sorry, the PDF couldn't be displayed :(</p>
        <a href="assets/Pdf/TermsConditions.pdf" target="_blank">Click Here</a>
      </object>
      <!--
      <div class="default-heading login-terms login-sub-separator">CBHS International Choice Network</div>
      <div class="default-paragraph-small login-terms login-separator">Terms and Conditions</div>
      <div class="default-paragraph-small login-separator">Effective 1<sup>st</sup>April 2021</div>
      <div class="terms-and-conds-body">
        <p class="default-paragraph">Please read these Terms and Conditions. Your use of and access to this Site
          constitutes your agreement to these Terms and Conditions as it relates to CBHS Health Fund Limited ABN 87 087 648
          717
          and CBHS Corporate Health Pty Ltd ABN 85 609 980 896 (<b>CBHS</b>).</p>
        <p class="default-paragraph">These Terms and Conditions may be amended by CBHS at any time and should be
          periodically reviewed.</p>
        <div class="default-heading2">Trade Marks</div>
        <p class="default-paragraph">All trade marks displayed on this Site are trademarks of CBHS. You must not use
          any logos found on this Site except as part of an authorised reproduction of information, text and graphics
          (<b>Material</b>) at this Site.</p>
        <div class="default-heading2">Copyright</div>
        <p class="default-paragraph">All Material on this Site is protected by copyright. Except as permitted below, you
          must not copy, modify, transmit, republish, reproduce, upload or distribute the Material at this Site without CBHS'
          express consent.</p>
        <div class="default-heading2">Use of Material</div>
        <p class="default-paragraph">You may only use, print, copy, reproduce, transmit or download information and
          Material from this Site to enable the health services provider by which you are employed to check the level of
          insurance, if any, of a patient requesting services from the provider(<b>Purpose</b>).</p>
        <p class="default-paragraph">In collecting personal information from this Site, and in using, storing and
          disclosing that information, you must comply in all respects with the Australian Privacy Principles under the
          Privacy Act 1988, including fully cooperating with CBHS to investigate any notifiable data breach and make any
          required notifications.</p>
        <div class="default-heading2">Confidentiality</div>
        <p class="default-paragraph">You may only disclose or distribute information and Material from this Site to
          employees, officers, advisers and contractors who have a need to know for the Purpose and who are aware that
          the information or Material from this Site is confidential.</p>
        <div class="default-heading2">Representation</div>
        <p class="default-paragraph">An inquiry may only be made of this Site in circumstances where collection of the
          information encompassed in the inquiry would be permitted by the Australian Privacy Principles under the
          Privacy Act 1988.</p>
        <p class="default-paragraph">Without limiting this requirement, you may only make an inquiry of, and collect
          information from this Site where:</p>
        <ul class="default-paragraph">
          <li>an eligible member of CBHS has asked you to provide services under an agreement between you and CBHS; and</li>
          <li>you have the eligible member's express or implied consent to check his or her benefit eligibility with CBHS.
          </li>
        </ul>
        <p class="default-paragraph">Collecting information from this Site is a representation that the requirements
          under this heading 'Representation' have been met.</p>
        <div class="default-heading2">Privacy Complaints</div>
        <p class="default-paragraph">If a complaint of an infringement of privacy is made that information has been
          collected from this Site or, if collected, has been used or disclosed in a manner contrary to the Australian
          Privacy Principles, then you agree to assist CBHS as required to deal with and resolve the complaint.</p>
        <div class="default-heading2">Prohibited Uses</div>
        <p class="default-paragraph">You must not:</p>
        <ul class="default-paragraph">
          <li>download, reverse engineer or decompile any software at this Site;</li>
          <li>provide hypertext links to, or frame, this Site in any other website; or</li>
          <li>upload to, distribute through or otherwise publish through this Site any harassing, abusive, defamatory,
            or offensive communication in any form.</li>
        </ul>
        <div class="default-heading2">Virus Warning</div>
        <p class="default-paragraph">The Internet and World Wide Web are unsecured, public networks. There is a risk that:</p>
        <ul class="default-paragraph">
          <li>information sent to or from this Site may be intercepted, corrupted or modified by third parties; and</li>
          <li>files obtained from or through this Site may contain computer viruses or other defects.</li>
        </ul>
        <p class="default-paragraph">In using this Site and Material from it, you assume these risks and responsibility
          for any loss or damage caused, directly or indirectly, by these risks.</p>
        <div class="default-heading2">Disclaimer</div>
        <p class="default-paragraph">This Site may contain hypertext links, frames or other references to other parties
          and their websites. We cannot control the content of those other websites, and make no warranty about the
          accuracy, currency or suitability of the material located on those websites. Those references are not an
          endorsement to those parties or their products or services.</p>
        <p class="default-paragraph">To the maximum extent permitted by law, CBHS excludes all terms, conditions and
          warranties implied by custom, the general law or statute and disclaims any liability to any person arising out
          of any action or failure to act by that person in accessing, down loading, using or relying on any Material from
          this Site.</p>
        <p class="default-paragraph">CBHS' liability under any condition or warranty which cannot be legally excluded is
          limited exclusively, at CBHS' option, to product or service replacement.</p>
      </div>
      -->
    </div>
  </div>
</div>
