<app-navbar></app-navbar>
<div class="default-page-container">
  <div class="default-page-internal admin-remittance-internal">
    <div class="back-btn" routerLink="/admin/remittance">
      <img src="../../../assets/Iconography/BackPurple.svg">
      <span class="default-heading2 back-txt">Back</span>
    </div>
    <div class="default-heading default-heading-sub-separator">
      <span>{{claim.providerName}}</span>
      <div class="admin-remittance-state-cont">
        <div class="default-heading2 admin-remittance-state-label">Status</div>
        <select class="default-input admin-remittance-state" [(ngModel)]="claim.state" (ngModelChange)="save()">
          <option value="submitted">Submitted</option>
          <option value="in_review">In Review</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>
    </div>
    <div class="default-paragraph-small default-heading-separator">Last Edited: {{this.utils.getDate(claim.lastEdited) | date:'medium'}}</div>
    <div class="default-heading2 invoice-submission-heading-remittance">
      Invoices
      <button *ngIf="claim.claimFiles.length > 0 && !utils.downloadingCf" class="default-btn default-btn-clear default-control-btn" (click)="downloadAllCf()">Download All</button>
      <button *ngIf="claim.claimFiles.length > 0 && utils.downloadingCf" class="default-btn default-btn-clear default-control-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
          class="loadingRotate"
          version="1.1"
          viewBox="0 0 56 56"
          height="5.292mm"
          width="5.292mm">
          <g
            transform="translate(-55.373511,-106.77655)">
            <path
              d="M 110.06486,141.6844 A 27.153975,26.689692 0 0 1 80.291831,161.23795 27.153975,26.689692 0 0 1 56.682161,134.7766 27.153975,26.689692 0 0 1 80.291831,108.31524 27.153975,26.689692 0 0 1 110.06486,127.8688"
              style="fill:none;fill-opacity:1;stroke:#5A3794;stroke-width:2.6173;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:115.842;stroke-opacity:1" />
          </g>
        </svg>
      </button>
      <button *ngIf="claim.bills.length > 0" class="default-btn default-btn-clear default-control-btn" (click)="generateCSV()">Download To CSV</button>
    </div>
    <div *ngIf="claim.bills.length > 0" class="direct-billing-container">
      <table class="direct-billing-table">
        <thead class="default-table-header">
        <tr>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Invoice No.</th>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Date</th>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Membership No.</th>
          <th class="table-header direct-billing-header direct-billing-cell-small">IRN</th>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Provider No.</th>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Item No.</th>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Fee</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of claim.bills; let i = index">
          <td class="direct-billing-row direct-billing-display direct-billing-cell direct-billing-cell-right-border">
            <div class="direct-billing-text">{{item.invoiceNum}}</div>
          </td>
          <td class="direct-billing-row direct-billing-display direct-billing-cell direct-billing-cell-right-border">
            <div class="direct-billing-text">{{this.utils.getDate(item.date) | date:'dd/MM/yyyy'}}</div>
          </td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-display direct-billing-cell direct-billing-cell-right-border"><div class="direct-billing-text">{{item.membershipNum}}</div></td>
          <td class="direct-billing-row direct-billing-cell-small direct-billing-display direct-billing-cell direct-billing-cell-right-border"><div class="direct-billing-text">{{item.irn}}</div></td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-display direct-billing-cell direct-billing-cell-right-border"><div class="direct-billing-text">{{item.providerNum}}</div></td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-display direct-billing-cell direct-billing-cell-right-border"><div class="direct-billing-text">{{item.itemNum}}</div></td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-display direct-billing-cell direct-billing-cell-right-border"><div class="direct-billing-text">{{item.fee | currency: 'USD'}}</div></td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td class="direct-billing-row direct-billing-cell-normal"></td>
          <td class="direct-billing-row direct-billing-cell-normal"></td>
          <td class="direct-billing-row direct-billing-cell-normal"></td>
          <td class="direct-billing-row direct-billing-cell-small"></td>
          <td class="direct-billing-row direct-billing-cell-normal"></td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-total-text">Total</td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-total-value">{{this.utils.calculateTotal(claim.bills) | currency: 'USD'}}</td>
        </tr>
        </tfoot>
      </table>
    </div>
    <hr *ngIf="claim.claimFiles.length > 0" class="default-bar">
    <div *ngIf="claim.claimFiles.length > 0" class="submitted-file-container default-heading-separator">
      <div *ngFor="let cf of claim.claimFiles" class="submitted-file-row" (click)="clickClaimFile(cf)">{{utils.getFilename(cf.filename)}}</div>
    </div>
    <div class="default-heading2 invoice-submission-heading-remittance">Notes</div>
    <textarea class="remittance-notes" [(ngModel)]="claim.remittanceNote" placeholder="Enter any notes here"></textarea>
    <div class="admin-remittance-btn-cont">
      <button *ngIf="!saveAnimation && !saving" class="default-btn default-btn-primary admin-remittance-btn" (click)="save()">Save</button>
      <button *ngIf="!saveAnimation && saving"  class="default-btn default-btn-primary admin-remittance-btn">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
          class="loadingRotate"
          version="1.1"
          viewBox="0 0 56 56"
          height="5.292mm"
          width="5.292mm">
          <g
            transform="translate(-55.373511,-106.77655)">
            <path
              d="M 110.06486,141.6844 A 27.153975,26.689692 0 0 1 80.291831,161.23795 27.153975,26.689692 0 0 1 56.682161,134.7766 27.153975,26.689692 0 0 1 80.291831,108.31524 27.153975,26.689692 0 0 1 110.06486,127.8688"
              style="fill:none;fill-opacity:1;stroke:#FFFFFF;stroke-width:2.6173;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:115.842;stroke-opacity:1" />
          </g>
        </svg>
      </button>
      <button *ngIf="saveAnimation" class="default-btn default-btn-secondary admin-remittance-btn">
        <svg class="check-svg check-svg-button" viewBox="0 0 26 26" xmlns="http://www.w3.org/2000/svg">
          <g stroke="currentColor" stroke-width="2" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
            <path class="circle" d="M13 1C6.372583 1 1 6.372583 1 13s5.372583 12 12 12 12-5.372583 12-12S19.627417 1 13 1z"/>
            <path class="tick" d="M6.5 13.5L10 17 l8.808621-8.308621"/>
          </g>
        </svg>
      </button>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<div *ngIf="invoiceView" class="default-modal" [@fadeInOnEnter] [@fadeOutOnLeave]>
  <div class="default-modal-backdrop" (click)="closeInvoice()"></div>
  <div class="default-modal-card file-upload-image">
    <div class="default-modal-exit no-select" (click)="closeInvoice()"><img class="default-modal-exit-cross" src="../../../assets/Iconography/CrossWhite.svg"></div>
    <app-file-viewer [media]="focus" style="width: 100%; height: 100%"></app-file-viewer>
  </div>
</div>
