<app-navbar></app-navbar>
<div class="default-page-container">
  <div class="default-page-internal footer-spacing">
    <div class="default-heading default-heading-separator">CBHS International Eligibility Check</div>
    <div class="eligibility-body-container">
      <div class="eligibility-body-section">
        <div class="default-input-group default-separator">
          <div class="default-heading3 default-small-separator">Membership ID<span *ngIf="membershipBlank" class="eligibility-error">Membership ID cannot be blank</span></div>
          <input class="default-input" placeholder="Membership ID" maxlength="9" [ngClass]="{'default-input-error': errorMembership}" [(ngModel)]="membershipNum" (keydown)="utils.membershipNumberKeyDown($event)" (blur)="verifyMembership()">
        </div>
        <div class="default-input-group default-separator">
          <div class="default-heading3 default-small-separator">IRN <div id="anchor" class="default-tooltip no-select eligibility-tooltip" (mouseenter)="showTooltip()" (focus)="showTooltip()" (mouseleave)="hideTooltip()" (blur)="hideTooltip()">i</div><span *ngIf="irnBlank" class="eligibility-error">IRN cannot be blank</span></div>
          <input class="default-input" placeholder="IRN Number" maxlength="2" [ngClass]="{'default-input-error': errorIrn}" [(ngModel)]="irn" (keydown)="utils.irnKeyDown($event)" (blur)="verifyIrn()">
        </div>
        <div class="default-input-group default-separator">
          <div class="default-heading3 default-small-separator">Date of Birth<span *ngIf="dobBlank" class="eligibility-error">Date of Birth cannot be blank</span></div>
          <div class="date-picker-container">
            <input id="eligibility-date" class="default-input eligibility-date" type="date" min="1900-01-01" [max]="maxDateVal"  [ngClass]="{'default-input-error': errorDate}" [ngModel] ="date | date:'yyyy-MM-dd'" (ngModelChange)="date = $event" [ngModelOptions]="{updateOn:'blur'}" (blur)="verifyDate()">
            <div class="calendar-picker-overlay">
              <img src="../../../assets/Iconography/CalendarWhite.svg">
            </div>
          </div>
        </div>
        <div class="eligibility-btn-group">
          <button class="default-btn default-btn-clear" (click)="clear()">Clear</button>
          <button *ngIf="!loading" class="default-btn default-btn-primary default-submit-btn" (click)="submit()">Submit</button>
          <button *ngIf="loading" class="default-btn default-btn-primary default-submit-btn">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
              class="loadingRotate"
              version="1.1"
              viewBox="0 0 56 56"
              height="5.292mm"
              width="5.292mm">
              <g
                transform="translate(-55.373511,-106.77655)">
                <path
                  d="M 110.06486,141.6844 A 27.153975,26.689692 0 0 1 80.291831,161.23795 27.153975,26.689692 0 0 1 56.682161,134.7766 27.153975,26.689692 0 0 1 80.291831,108.31524 27.153975,26.689692 0 0 1 110.06486,127.8688"
                  style="fill:none;fill-opacity:1;stroke:#FFFFFF;stroke-width:2.6173;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:115.842;stroke-opacity:1" />
              </g>
            </svg>
          </button>
        </div>
      </div>
      <div class="eligibility-body-section">
        <div *ngIf="submitted" class="default-heading3 default-small-separator eligibility-status-heading">Membership Eligibility Status</div>
        <div *ngIf="submitted && !success" class="eligibility-alert eligibility-alert-error">
          <div class="eligibility-alert-icon">
            <img class="eligibility-cross" src="../../../assets/Iconography/CrossRed.svg">
          </div>
          <div class="default-heading3">{{eligibilityErrorMessage}}</div>
        </div>
        <div *ngIf="submitted && success" class="eligibility-alert eligibility-alert-success">
          <div class="eligibility-alert-icon">
            <img class="eligibility-cross" src="../../../assets/Iconography/CheckGreen.svg">
          </div>
          <div class="default-heading3">Status: Member is Eligible</div>
        </div>
        <div *ngIf="submitted && success" class="eligibility-success-info">
          <div class="eligibility-success-info-group eligibility-success-info-separator">
            <div class="default-heading3 eligibility-success-info-heading">Membership Number:</div>
            <div class="default-paragraph-small">{{membershipData.membershipNumber}}</div>
          </div>
          <div class="eligibility-success-info-group eligibility-success-info-separator">
            <div class="default-heading3 eligibility-success-info-heading">Name:</div>
            <div class="default-paragraph-small">{{membershipData.name}}</div>
          </div>
          <div class="eligibility-success-info-group">
            <div class="default-heading3 eligibility-success-info-heading">Product:</div>
            <div class="default-paragraph-small">{{membershipData.productName}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<div id="tooltip" class="tooltip" [ngStyle]="{'top': tooltipTop + 'px', 'left': tooltipLeft + 'px'}">
  <img class="tooltip-image" src="../../../assets/Tooltip/Irn-tooltip.png">
</div>
