<app-navbar></app-navbar>
<div class="default-page-container">
  <div class="default-page-internal footer-spacing">
    <div class="default-heading default-heading-sub-separator">View Remittance</div>
    <div class="default-paragraph-small default-heading-separator">Click <a href="remittance#claims">here</a> to view your claim history</div>
    <div class="default-heading2 remittance-manager-separator">Remittance</div>
    <div class="table-container">
      <table class="default-table">
        <thead class="default-table-header">
        <tr>
          <th class=" table-header date-small-cell">Date Created</th>
          <th class="table-header">Subject</th>
        </tr>
        </thead>
        <tbody class="file-upload-table-body">
        <tr *ngIf="remittanceService.remittances.length <= 0" class="default-table-row">
          <td class="default-cell default-empty-row" colspan="2"><span *ngIf="!remittanceService.loadingPage">No remittance files</span><span *ngIf="remittanceService.loadingPage">Loading ...</span></td>
        </tr>
        <tr *ngFor="let r of remittanceService.remittances; let ri = index" (click)="openInvoice(ri)" class="default-table-row">
          <td class="default-cell">{{this.utils.getSydneyTime(r.createDateTime) | date:'dd/MM/yyy'}}</td>
          <td class="default-cell">Provider Remittance</td>
        </tr>
        </tbody>
      </table>
      <div class="default-pagination-row default-heading-separator">
        <div *ngIf="remittanceService.remittances.length > 0" class="default-pagination-info">Displaying records {{getDisplayStartRemittance()}}-{{getDisplayEndRemittance()}} of {{remittanceService.count}}</div>
        <div *ngIf="remittanceService.remittances.length <= 0" class="default-pagination-info">Displaying records 0 of {{remittanceService.count}}</div>
        <div *ngIf="this.remittanceService.pageNumbers.length > 1" class="default-pagination-selectors">
          <div *ngFor="let p of remittanceService.pageNumbers" class="default-pagination-selector no-select" [ngClass]="{'default-pagination-active': p == remittanceService.page}" (click)="clickPageRemittance(p)">{{p}}</div>
        </div>
      </div>
      <div id="claims" class="default-heading2 remittance-manager-separator">Claims History</div>
      <div class="table-container">
        <table class="default-table">
          <thead class="default-table-header">
          <tr>
            <th class="table-header date-small-cell">Claim Type</th>
            <th class=" table-header">Date Created</th>
            <th class="table-header state-cell">Status</th>
          </tr>
          </thead>
          <tbody class="file-upload-table-body">
          <tr *ngIf="claimsService.remittance.length <= 0" class="default-table-row">
            <td class="default-cell default-empty-row" colspan="3"><span *ngIf="!loadingClaims">No Claims yet</span><span *ngIf="loadingClaims">Loading ...</span></td>
          </tr>
          <tr *ngFor="let r of claimsService.remittance; let ri = index" (click)="clickItem(r)" class="default-table-row">
            <td class="default-cell"><span *ngIf="r.type == 'direct'">Direct Bill</span><span *ngIf="r.type == 'invoice'">Invoice</span></td>
            <td class="default-cell">{{this.utils.getDate(r.created) | date:'dd/MM/yyy'}}</td>
            <td class="default-cell state-cell">
              <div class="status-pill" [ngClass]="{'status-pill-submitted': r.state == 'submitted', 'status-pill-in-review': r.state == 'in_review', 'status-pill-approved': r.state == 'approved', 'status-pill-rejected': r.state == 'rejected'}">{{utils.getState(r.state)}}</div>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="default-pagination-row remittance-manager-bottom">
          <div *ngIf="claimsService.remittance.length > 0" class="default-pagination-info">Displaying records {{getDisplayStart()}}-{{getDisplayEnd()}} of {{claimsService.count}}</div>
          <div *ngIf="claimsService.remittance.length <= 0" class="default-pagination-info">Displaying records 0 of {{claimsService.count}}</div>
          <div *ngIf="this.pageNumbers.length > 1" class="default-pagination-selectors">
            <div *ngFor="let p of pageNumbers" class="default-pagination-selector no-select" [ngClass]="{'default-pagination-active': p == claimsService.page}" (click)="clickPage(p)">{{p}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<div *ngIf="invoiceView" class="default-modal" [@fadeInOnEnter] [@fadeOutOnLeave]>
  <div class="default-modal-backdrop" (click)="closeInvoice()"></div>
  <div class="default-modal-card file-upload-image">
    <div class="default-modal-exit no-select" (click)="closeInvoice()"><img class="default-modal-exit-cross" src="../../../assets/Iconography/CrossWhite.svg"></div>
    <app-file-viewer-remittance [media]="remittanceService.remittance" style="width: 100%; height: 100%"></app-file-viewer-remittance>
  </div>
</div>
