import {ChangeDetectorRef, Component, HostListener, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import * as uuid from 'uuid';
import {ClaimsService} from "../../services/claims.service";
import {CurrencyPipe, DatePipe} from "@angular/common";
import {UtilitiesService} from "../../services/utilities.service";
import {NavService} from "../../services/nav.service";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-direct-billing',
  templateUrl: './direct-billing.component.html',
  styleUrls: ['./direct-billing.component.scss']
})
export class DirectBillingComponent implements OnInit {

  public directBillingUuid = uuid.v4();

  public focusTrigger: any;

  public saveLoading = false;

  public errors = [
    [false, false, false, false, false, false, false]
  ];

  public items = [
    {
      invoiceNum: "",
      date: null,
      membershipNum: "",
      irn: "",
      providerNum: "",
      itemNum: "",
      fee: "",
    }
  ];

  public minDate = "";

  public minDateVal: any;

  public maxDate = "";

  public maxDateVal: any;

  public tooltipLeft = 0;

  public tooltipTop = 0;

  public verified = true;

  public errorEmpty = false;

  public errorInvalid = false;

  public errorInvalidCharacter = false;

  public errorInvalidCharacterStartsWith = false;

  @HostListener("window:keydown", ['$event'])
  onKeyDown(ev:KeyboardEvent){
  }


  constructor(
    public auth: AuthService,
    public claimsService: ClaimsService,
    private currencyPipe: CurrencyPipe,
    public utils: UtilitiesService,
    public ref: ChangeDetectorRef,
    public navService: NavService,
    public alert: AlertService,
    public datePipe: DatePipe) {
    this.navService.navTab = 1;
    this.auth.get();
    this.maxDateVal = new Date();
    this.minDateVal = new Date();
    this.maxDateVal.setHours(23,59,59,999);
    this.maxDate = this.datePipe.transform(this.maxDateVal, 'yyyy-MM-dd');
    this.minDateVal.setFullYear(this.minDateVal.getFullYear() - 2);
    this.minDateVal.setDate(this.minDateVal.getDate() - 1);
    this.minDateVal.setHours(23,59,59,999);
    this.minDate = this.datePipe.transform(this.minDateVal, 'yyyy-MM-dd');
    this.auth.verifyActivityStatus();
  }

  ngOnInit(): void {
  }

  /**
   * Add invoice item to list
   */
  public addItem(){
    if (this.items.length < 100){
      this.items.push({
        invoiceNum: "",
        date: null,
        membershipNum: "",
        irn: "",
        providerNum: "",
        itemNum: "",
        fee: "",
      });
      this.errors.push([false, false, false, false, false, false, false]);
    }
  }

  /**
   * Remove invoice from item list
   *
   * @param {number} pos - position of the item in the list to remove
   */
  public removeItem(pos){
    this.items.splice(pos, 1);
    this.errors.splice(pos, 1);
  }

  /**
   * Submit the direct bill invoice
   */
  public submit(){
    this.saveLoading = true;
    this.verified = this.verifyInputs();
    if (this.items.length > 0 && this.verified){
      this.claimsService.submitDirect(this.items, this.auth.token.providerNum, this.auth.token.providerName)
        .subscribe(
          data => {
            this.saveLoading = false;
            this.items = [
              {
                invoiceNum: "",
                date: null,
                membershipNum: "",
                irn: "",
                providerNum: "",
                itemNum: "",
                fee: "",
              }
            ];
            this.errors = [
              [false, false, false, false, false, false, false]
            ];
            this.alert.success("Success, your bill(s) have been submitted");
          },
          err => {
            this.saveLoading = false;
            this.alert.error("Your bill(s) could not be submitted please try again");
          }
        );
    } else {
      this.saveLoading = false;
    }
  }

  /**
   * Validate the currency, updating both the direct bill item and the error list.
   *
   * @param {string} currency - String containing the Fee with australian dollar currency spacings
   * @param {any} item - The direct bill item this currency relates
   * @param {number} pos - The position of the item in the item list
   */
  public currencyConverter(currency, item, pos){
    try{
      item.fee = this.currencyPipe.transform(currency.replace(/(?!\.)\D/g, '').replace(/^0+/, ''), 'USD', 'symbol', '2.2');
      this.ref.detectChanges();
      this.errors[pos][6] = !this.utils.verifyCurrency(this.items[pos].fee);
    } catch (e){
      this.errors[pos][6] = true;
    }
  }

  /**
   * Verify the date field on a blur event
   *
   * @param {number} pos - The position of the item in the item list
   */
  public verifyDateBlur(pos){
    this.errors[pos][1] = !this.utils.verifyDate(this.items[pos].date, this.minDateVal, this.maxDateVal);
  }

  /**
   * Verify the membership number on blur event
   *
   * @param {number} pos - The position of the item in the item list
   */
  public verifyMembership(pos){
    this.errors[pos][2] = !this.utils.verifyFreeText(this.items[pos].membershipNum, 9);
  }

  /**
   * Verify the provider number on the blur event
   *
   * @param {number} pos - The position of the item in the item list
   */
  public verifyProvider(pos){
    this.errors[pos][4] = !this.utils.verifyFreeText(this.items[pos].providerNum, 9);
  }

  /**
   * Verify the item number on the blur event
   *
   * @param {number} pos - The position of the item in the item list
   */
  public verifyItem(pos){
    this.errors[pos][5] = !this.utils.verifyFreeText(this.items[pos].itemNum, 9);
  }

  /**
   * Verify all of the inputs of submitted items to the list.
   */
  public verifyInputs(){
    var verified = true;
    this.errorEmpty = false;
    this.errorInvalid = false;
    this.errorInvalidCharacter = false;
    this.errorInvalidCharacterStartsWith = false;
    for (var i=0; i < this.items.length; i++){
      var errorDate = !this.utils.verifyDate(this.items[i].date, this.minDateVal, this.maxDateVal);
      var errorMembership = !this.utils.verifyFreeText(this.items[i].membershipNum, 9);
      var errorIrn = !this.utils.verifyIrn(this.items[i].irn);
      var errorProviderNum = !this.utils.verifyFreeText(this.items[i].providerNum, 9);
      var errorItemNum = !this.utils.verifyAlphaNumeric(this.items[i].itemNum, 6);
      var errorFee = !this.utils.verifyCurrency(this.items[i].fee);
      this.errors[i][0] = !this.utils.verifyFreeText(this.items[i].invoiceNum, 20) || !this.utils.verifyCharacters(this.items[i].invoiceNum) || this.items[i].invoiceNum == "" || !this.utils.verifyCharactersStartsWith(this.items[i].invoiceNum);
      this.errorEmpty = this.errorEmpty || this.items[i].invoiceNum == "";
      this.errorInvalid = this.errorInvalid || !this.utils.verifyFreeText(this.items[i].invoiceNum, 20);
      this.errorInvalidCharacter = this.errorInvalidCharacter || !this.utils.verifyCharacters(this.items[i].invoiceNum);
      this.errorInvalidCharacterStartsWith = this.errorInvalidCharacterStartsWith || !this.utils.verifyCharactersStartsWith(this.items[i].invoiceNum);
      this.errors[i][1] = errorDate;
      this.errorEmpty = this.errorEmpty || errorDate && (this.items[i].date == "" || this.items[i].date == null);
      this.errorInvalid = this.errorInvalid || errorDate && (this.items[i].date != "" && this.items[i].date != null);
      this.errors[i][2] = errorMembership;
      this.errorEmpty = this.errorEmpty || errorMembership && (this.items[i].membershipNum == "" || this.items[i].membershipNum == null);
      this.errorInvalid = this.errorInvalid || errorMembership && this.items[i].membershipNum != "" && this.items[i].membershipNum != null;
      this.errorInvalidCharacter = this.errorInvalidCharacter || !this.utils.verifyCharacters(this.items[i].membershipNum);
      this.errorInvalidCharacterStartsWith = this.errorInvalidCharacterStartsWith || !this.utils.verifyCharactersStartsWith(this.items[i].membershipNum);
      this.errors[i][3] = errorIrn;
      this.errorEmpty = this.errorEmpty || errorIrn && (this.items[i].irn == "" || this.items[i].irn == null);
      this.errorInvalid = this.errorInvalid || errorIrn && this.items[i].irn != "" && this.items[i].irn != null;
      this.errors[i][4] = errorProviderNum;
      this.errorEmpty = this.errorEmpty || errorProviderNum && (this.items[i].providerNum == "" || this.items[i].providerNum == null);
      this.errorInvalid = this.errorInvalid || errorProviderNum && this.items[i].providerNum != "" && this.items[i].providerNum != null;
      this.errorInvalidCharacter = this.errorInvalidCharacter || !this.utils.verifyCharacters(this.items[i].providerNum);
      this.errorInvalidCharacterStartsWith = this.errorInvalidCharacterStartsWith || !this.utils.verifyCharactersStartsWith(this.items[i].providerNum);
      this.errors[i][5] = errorItemNum;
      this.errorEmpty = this.errorEmpty || errorItemNum && (this.items[i].itemNum == "" || this.items[i].itemNum == null);
      this.errorInvalid = this.errorInvalid || errorItemNum && this.items[i].itemNum != "" && this.items[i].itemNum != null;
      this.errorInvalidCharacter = this.errorInvalidCharacter || !this.utils.verifyCharacters(this.items[i].itemNum);
      this.errorInvalidCharacterStartsWith = this.errorInvalidCharacterStartsWith || !this.utils.verifyCharactersStartsWith(this.items[i].itemNum);
      this.errors[i][6] = errorFee;
      this.errorEmpty = this.errorEmpty || errorFee && (this.items[i].fee == "" || this.items[i].fee == null);
      this.errorInvalid = this.errorInvalid || errorFee && this.items[i].fee != "" && this.items[i].fee != null;
      verified = verified && this.errors[i].every(x => x == false)
    }
    return verified;
  }

  /**
   * Clear the item list and revert to the default values
   */
  public clear(){
    this.items = [
      {
        invoiceNum: "",
        date: null,
        membershipNum: "",
        irn: "",
        providerNum: "",
        itemNum: "",
        fee: "",
      }
    ];
    this.errors = [
      [false, false, false, false, false, false, false]
    ];
  }

  /**
   * Show the IRN tooltip when hovered over by the user
   */
  public showTooltip(){
    var el = <HTMLElement>document.getElementById("direct-tooltip");
    var anchor = <HTMLElement>document.getElementById("direct-anchor");
    var rect = anchor.getBoundingClientRect();
    this.tooltipLeft = rect.right + 10;
    this.tooltipTop = rect.top - 150;
    el.setAttribute('data-show', '');
  }

  /**
   * Hide the IRN tooltip when the mouse leaves the tooltip
   */
  public hideTooltip(){
    var el = <HTMLElement>document.getElementById("direct-tooltip");
    el.removeAttribute('data-show');
  }

  /**
   * Show the provider number tooltip when the user hovers
   */
  public showTooltipProvider(){
    var el = <HTMLElement>document.getElementById("direct-tooltip-provider");
    var anchor = <HTMLElement>document.getElementById("direct-provider-anchor");
    var rect = anchor.getBoundingClientRect();
    this.tooltipLeft = rect.right + 10;
    this.tooltipTop = rect.top;
    el.setAttribute('data-show', '');
  }

  /**
   * Show the provider number tooltip when the user hovers
   */
  public hideTooltipProvider(){
    var el = <HTMLElement>document.getElementById("direct-tooltip-provider");
    el.removeAttribute('data-show');
  }

  /**
   * Verify the IRN number is within expected
   */
  public verifyIrn(irn, pos){
    if (irn.length > 2 || Number.isNaN(+irn) || +irn <= 0){
      this.errors[pos][3] = true;
    } else if (irn == ""){
      this.errors[pos][3] = true;
    } else if (+irn < 10){

      this.errors[pos][3] = false;
      var x = +irn;
      this.items[pos].irn = "0" + x;
    } else {
      this.errors[pos][3] = false;
    }
  }

  public verifyInvoiceNum(invoiceNum, pos){
    this.errors[pos][0] = !this.utils.verifyFreeText(invoiceNum, 20);
  }

}
