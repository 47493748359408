import { Component, OnInit } from '@angular/core';
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from "angular-animations";
import {AlertService} from "../../services/alert.service";

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 300}),
    fadeOutOnLeaveAnimation({ duration: 300})
  ]
})
export class AlertsComponent implements OnInit {

  constructor(public alert: AlertService) { }

  ngOnInit(): void {
  }

  /**
   * Close the alert modal
   */
  public close(){
    this.alert.modal = false;
  }

}
