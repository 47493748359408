import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from 'src/app/components/navbar/navbar.component';
import { FileDropComponent } from 'src/app/components/file-drop/file-drop.component';
import { FileViewerRemittanceComponent } from 'src/app/components/file-viewer-remittance/file-viewer-remittance.component';
import { AlertsComponent } from 'src/app/components/alerts/alerts.component';
import { FileViewerComponent } from 'src/app/components/file-viewer/file-viewer.component';
import { FormsModule } from '@angular/forms';
import {FooterComponent} from "../../components/footer/footer.component";



@NgModule({
  declarations: [
    NavbarComponent,
    FileViewerComponent,
    AlertsComponent,
    FileViewerRemittanceComponent,
    FileDropComponent,
    FooterComponent
  ],
  exports: [
    NavbarComponent,
    FileViewerComponent,
    AlertsComponent,
    FileViewerRemittanceComponent,
    FileDropComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule { }
