import { NgModule, APP_INITIALIZER } from '@angular/core';
import {
    MSAL_CONFIG,
    MSAL_CONFIG_ANGULAR,
    MsalAngularConfiguration
    , MsalService, MsalModule, MsalInterceptor
  } from '@azure/msal-angular';
import { Configuration } from 'msal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ConfigurationService } from "../services/configuration.service";

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const hostname = window.location.host;
const protocol = window.location.protocol;
const domain = protocol + "//" + hostname;


const appInitializerFn = (appConfig: ConfigurationService) => {
    return () => {
      return appConfig.loadConfig();
    };
  };


export function msalConfigFactory(config: ConfigurationService): Configuration {
    const auth = {
        auth: {
            clientId: config.FrontendClientId, 
            authority: config.authority,
            redirectUri: domain + config.redirectUri 
        },
        cache: {
            cacheLocation: config.cacheLocation, 
            storeAuthStateInCookie: isIE
        }
    };
    return (auth as Configuration);
}

export function msalAngularConfigFactory(config: ConfigurationService): MsalAngularConfiguration {
    const scope = config.Scope;
    const auth = {
        popUp: !isIE,
        consentScopes: [
          'user.read',
          scope
        ],
        unprotectedResources: [],
        protectedResourceMap: [
            [domain + '/api/Claim/admin',[scope]],
            [domain + '/api/Claim/admin/*',[scope]],
            [domain + '/api/Claim/admin/zip/*',[scope]],
            [domain + '/api/Media/admin',[scope]],
            [domain + '/api/Media/admin/*',[scope]],
            [domain + '/api/Provider/admin',[scope]],
            [domain + '/api/State/admin',[scope]],
            ['https://graph.microsoft.com/v1.0/me', ['user.read']],
            [domain + '/api/Provider', null],
            [domain + '/api/Provider/get', null],
            [domain + '/api/Claim', null],
            [domain + '/api/Claim/count', null],
            [domain + '/api/Claim/get/*', null],
            [domain + '/api/Claim/submitted/*', null],
            [domain + '/api/Claim/media/*', null],
            [domain + '/api/Eligibility', null],
            [domain + '/api/Media', null],
            [domain + '/api/Media/get/*', null],
            [domain + '/api/Media/delete/*', null],
            [domain + '/api/Media/filename/*', null],
            [domain + '/api/Media/filename/*', null],
            [domain + '/api/Remittance', null],
            [domain + '/api/Remittance/*', null],
            [domain + '/api/State', null],
            [domain + '/api/Timeout', null],
            [domain + '/api/Timeout/*', null],
            [domain + '/assets/appsettings.json', null]
          ],
          extraQueryParameters: {}
    };
    return (auth as MsalAngularConfiguration);
}

@NgModule({
    providers: [
    ],
    imports: [MsalModule]
})
export class MsalApplicationModule {

    static forRoot() {
        return {
            ngModule: MsalApplicationModule,
            providers: [
                ConfigurationService,
                {
                  provide: APP_INITIALIZER,
                  useFactory: appInitializerFn,
                  multi: true,
                  deps: [ConfigurationService]
                },                
                {
                    provide: MSAL_CONFIG,
                    useFactory: msalConfigFactory,
                    deps: [ConfigurationService]
                },
                {
                    provide: MSAL_CONFIG_ANGULAR,
                    useFactory: msalAngularConfigFactory,
                    deps: [ConfigurationService]
                },
                MsalService,
                {
                    provide: HTTP_INTERCEPTORS,
                    useClass: MsalInterceptor,
                    multi: true
                }
            ]
        };
    }
}
