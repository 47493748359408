<div class="navbar">
  <div class="navbar-internal default-page-width">
    <div class="navbar-primary-row">
      <img class="navbar-logo" src="../../../assets/Logo/Logo-55.png" alt="logo">
      <div class="navbar-primary-right">
        <div *ngIf="!auth.admin" class="navbar-login-info default-paragraph-small">Logged in as:<br><span class="bold-txt">{{auth.token.providerName}}</span></div>
        <button class="default-btn default-btn-secondary" (click)="auth.logout()">Logout</button>
      </div>
    </div>
    <div class="navbar-btn-container">
      <div class="navbar-btn-container-left">
        <div *ngIf="!auth.admin" class="navbar-btn no-select" [ngClass]="{'navbar-btn-active': navService.navTab == 0}" (click)="clickPos(0)">Member Eligibility</div>
        <div *ngIf="!auth.admin" class="navbar-btn no-select" [ngClass]="{'navbar-btn-active': navService.navTab == 1}" (click)="clickPos(1)">Direct Bill</div>
        <div *ngIf="!auth.admin" class="navbar-btn no-select" [ngClass]="{'navbar-btn-active': navService.navTab == 2}" (click)="clickPos(2)">Submit Invoice</div>
        <div *ngIf="!auth.admin" class="navbar-btn no-select" [ngClass]="{'navbar-btn-active': navService.navTab == 3}" (click)="clickPos(3)">View Remittance</div>
        <div *ngIf="auth.admin" class="navbar-btn no-select" [ngClass]="{'navbar-btn-active': navService.navTab == 4}" (click)="clickPos(4)">View Claims</div>
      </div>
      <div class="navbar-btn-container-right">
        <div *ngIf="!auth.admin" class="navbar-btn no-select" [ngClass]="{'navbar-btn-active': navService.navTab == 5}" (click)="clickPos(5)">My Settings</div>
      </div>
    </div>
  </div>
</div>
