<app-navbar></app-navbar>
<div class="default-page-container">
  <div class="default-page-internal remittance-manager-admin">
    <div class="default-heading default-heading-sub-separator">View Claims <button class="default-btn default-btn-clear default-control-btn" (click)="openReport()">Download Report</button></div>
    <div class="search-container">
      <input class="default-input remittance-search" [(ngModel)]="claimsService.query" placeholder="Search" (keyup)="search()">
      <div class="remittance-search-box" (click)="search()"><img class="search-icon" src="../../../assets/Iconography/Search.svg"></div>
    </div>
    <div class="filter-container">
      <div class="default-input-group filter-input-group">
        <div class="default-heading2 default-small-separator">Date From</div>
        <input class="default-input filter-date" type="datetime-local" [max]="maxDate" [min]="minDate" [ngModel] ="claimsService.filterDateStart | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="claimsService.filterDateStart = $event" [ngModelOptions]="{updateOn:'blur'}" (blur)="filterChange()">
      </div>
      <div class="default-input-group filter-input-group">
        <div class="default-heading2 default-small-separator">Date To</div>
        <input class="default-input filter-date" type="datetime-local" [max]="maxDate" [min]="minDate" [ngModel] ="claimsService.filterDateEnd | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="claimsService.filterDateEnd = $event" [ngModelOptions]="{updateOn:'blur'}" (blur)="filterChange()">
      </div>
      <div class="default-input-group filter-input-group-small">
        <div class="default-heading2 default-small-separator">Type</div>
        <select class="default-input filter-select" [(ngModel)]="claimsService.filterType" (ngModelChange)="this.filterChange()">
          <option value="any">All</option>
          <option value="invoice">Invoice</option>
          <option value="direct">Direct Bill</option>
        </select>
      </div>
      <div class="default-input-group filter-input-group-small">
        <div class="default-heading2 default-small-separator">Status</div>
        <select class="default-input filter-select" [(ngModel)]="claimsService.filterStatus" (ngModelChange)="this.filterChange()">
          <option value="any">All</option>
          <option value="submitted">Submitted</option>
          <option value="in_review">In Review</option>
          <option value="approved">Approved</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>
      <div class="default-input-group filter-input-group filter-btn">
        <button class="default-btn default-btn-clear" (click)="clearFilters()">Clear</button>
      </div>
    </div>
    <div class="filter-error">
      <div *ngIf="claimsService.filterError || claimsService.filterPastFutureError" class="default-heading3 remittance-manager-default-error-msg">Invalid date/time</div>
    </div>
    <div class="table-container">
      <table class="default-table">
        <thead class="default-table-header">
        <tr>
          <th class=" table-header date-small-cell">Date Created</th>
          <th class="table-header">Provider</th>
          <th class="table-header">Last Modified</th>
          <th class="table-header centered-cell">Type</th>
          <th class="table-header centered-cell">Num. Invoices</th>
          <th class="table-header state-cell centered-cell">Status</th>
          <th class="table-header state-cell centered-cell">Download</th>
        </tr>
        </thead>
        <tbody class="file-upload-table-body">
        <tr *ngIf="claimsService.remittance.length <= 0" class="default-table-row">
          <td class="default-cell default-empty-row" colspan="7"><span *ngIf="!loadingClaims">No Claims yet</span><span *ngIf="loadingClaims">Loading ...</span></td>
        </tr>
        <tr *ngFor="let r of claimsService.remittance; let ri = index" (dblclick)="clickItem(r)" class="default-table-row">
          <td class="default-cell no-select">{{this.utils.getDate(r.created) | date:'dd/MM/yyy h:mm:ss a'}}</td>
          <td class="default-cell no-select">{{r.providerName}}</td>
          <td class="default-cell no-select">{{this.utils.getDate(r.lastEdited) | date:'dd/MM/yyy h:mm:ss a'}}</td>
          <td class="default-cell centered-cell no-select"><span *ngIf="r.type == 'direct'">Direct Bill</span><span *ngIf="r.type == 'invoice'">Invoice</span></td>
          <td class="default-cell centered-cell no-select"><span *ngIf="r.type == 'direct'">1</span><span *ngIf="r.type == 'invoice'">{{r.claimFiles.length}}</span></td>
          <td class="default-cell state-cell no-select">
            <select class="default-input admin-remittance-state" [(ngModel)]="r.state" (ngModelChange)="saveClaim(r, ri)" [ngClass]="{'saved-remittance': savingState[ri]}">
              <option value="submitted">Submitted</option>
              <option value="in_review">In Review</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
            </select>
          </td>
          <td class="default-cell centered-cell" (click)="download(r, ri)">
            <img *ngIf="!downloadState[ri]" src="../../../assets/Iconography/DownloadPurple.svg">
            <span *ngIf="downloadState[ri]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                class="loadingRotate"
                version="1.1"
                viewBox="0 0 56 56"
                height="5.292mm"
                width="5.292mm">
              <g
                transform="translate(-55.373511,-106.77655)">
                <path
                  d="M 110.06486,141.6844 A 27.153975,26.689692 0 0 1 80.291831,161.23795 27.153975,26.689692 0 0 1 56.682161,134.7766 27.153975,26.689692 0 0 1 80.291831,108.31524 27.153975,26.689692 0 0 1 110.06486,127.8688"
                  style="fill:none;fill-opacity:1;stroke:#5A3794;stroke-width:2.6173;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:115.842;stroke-opacity:1" />
              </g>
            </svg>
            </span>

          </td>
        </tr>
        </tbody>
      </table>
      <div class="default-pagination-row">
        <div *ngIf="claimsService.remittance.length > 0" class="default-pagination-info">Displaying records {{getDisplayStart()}}-{{getDisplayEnd()}} of {{claimsService.count}}</div>
        <div *ngIf="claimsService.remittance.length <= 0" class="default-pagination-info">Displaying records 0 of {{claimsService.count}}</div>
        <div *ngIf="this.pageNumbers.length >= 1" class="default-pagination-selectors">
          <div *ngFor="let p of pageNumbers" class="default-pagination-selector no-select" [ngClass]="{'default-pagination-active': p == claimsService.page}" (click)="clickPage(p)">{{p}}</div>
        </div>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<div *ngIf="refresh" class="refresh-alert no-select" (click)="clickRefresh()" [@fadeInRightOnEnter] [@fadeOutRightOnLeave]>Refresh</div>
<div *ngIf="reportModal" class="default-modal" [@fadeInOnEnter] [@fadeOutOnLeave]>
  <div class="default-modal-backdrop" (click)="closeReport()"></div>
  <div class="default-modal-card remittance-admin-card">
    <div class="default-modal-exit no-select" (click)="closeReport()"><img class="default-modal-exit-cross" src="../../../assets/Iconography/CrossWhite.svg"></div>
    <div class="default-heading default-heading-sub-separator">Generate Report</div>
    <div class="default-paragraph-small remittance-admin-modal-separator">Select the date of the report</div>
    <div class="default-heading3 default-small-separator">From:</div>
    <div class="date-picker-container remittance-admin-modal-separator">
      <input class="default-input remittance-modal-date" type="datetime-local" [max]="maxDate" [min]="minDate" [ngClass]="{'default-input-error': reportGenerationError}" [ngModel] ="reportDateStart | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="reportDateStart = $event" [ngModelOptions]="{updateOn:'blur'}" (blur)="validateReportDates()">
      <div class="calendar-picker-overlay">
        <img src="../../../assets/Iconography/CalendarWhite.svg">
      </div>
    </div>
    <div class="default-heading3 default-small-separator">To:</div>
    <div class="date-picker-container remittance-admin-modal-footer-separator">
      <input class="default-input remittance-modal-date" type="datetime-local" [max]="maxDate" [min]="minDate" [ngClass]="{'default-input-error': reportGenerationError}" [ngModel] ="reportDateEnd | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="reportDateEnd = $event" [ngModelOptions]="{updateOn:'blur'}" (blur)="validateReportDates()">
      <div class="calendar-picker-overlay">
        <img src="../../../assets/Iconography/CalendarWhite.svg">
      </div>
    </div>
    <div *ngIf="reportGenerationError" class="default-heading3 remitance-manager-error-msg">{{reportGenerationErrorMessage}}</div>
    <button *ngIf="!loadingReport" class="default-btn default-btn-primary remittance-modal-btn" (click)="generateReport()">Generate</button>
    <button *ngIf="loadingReport" class="default-btn default-btn-primary remittance-modal-btn">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
        class="loadingRotate"
        version="1.1"
        viewBox="0 0 56 56"
        height="5.292mm"
        width="5.292mm">
        <g
          transform="translate(-55.373511,-106.77655)">
          <path
            d="M 110.06486,141.6844 A 27.153975,26.689692 0 0 1 80.291831,161.23795 27.153975,26.689692 0 0 1 56.682161,134.7766 27.153975,26.689692 0 0 1 80.291831,108.31524 27.153975,26.689692 0 0 1 110.06486,127.8688"
            style="fill:none;fill-opacity:1;stroke:#FFFFFF;stroke-width:2.6173;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:115.842;stroke-opacity:1" />
        </g>
      </svg>
    </button>
  </div>
</div>



