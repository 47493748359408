import { Injectable } from '@angular/core';
import {PropertiesService} from "./properties.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {Cookie} from "ng2-cookies/ng2-cookies";

@Injectable({
  providedIn: 'root'
})
export class TimeoutService {

  constructor(public prop: PropertiesService,
              private http: HttpClient,
              public auth: AuthService) { }

  /**
   * Save updated timeout
   *
   * @param claim
   */
  public update(timeout){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.timeoutApi;
    return this.http.post(uri, timeout, {headers: headers});
  }

}
