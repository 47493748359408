import { Component, OnInit } from '@angular/core';
import {AuthService} from "../../services/auth.service";
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from "angular-animations";
import { ConfigurationService } from "../../services/configuration.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 300}),
    fadeOutOnLeaveAnimation({ duration: 300})
  ]
})
export class LoginComponent implements OnInit {

  public providerNum = "";

  public password = "";

  public termsAndCond = false;

  public passwordBlank = false;

  public providerBlank = false;
  public formURL;
  public providerRelationEmail;
  public providerRelationTel;
  public providerEmail;
  public providerTel;
  public providerHelperURL;

  constructor(private appConfigService: ConfigurationService, public auth: AuthService) {
    this.auth.admin = false;
    this.formURL = this.appConfigService.FormUrl;
    this.providerRelationEmail = this.appConfigService.ProviderRelationEmail;
    this.providerRelationTel = this.appConfigService.ProviderRelationTel;
    this.providerEmail = this.appConfigService.ProviderEmail;
    this.providerTel = this.appConfigService.ProviderTel;
    this.providerHelperURL = this.appConfigService.ProviderHelperURL;
  }

  ngOnInit(): void {
  }

  /**
   * Listen for the enter key to be pressed.
   *
   * @param event
   */
  keyUp(event){
    if (event.key == "Enter"){
      this.submit();
    }
  }

  /**
   * Submit the the username and password to the backend to verify user is valid.
   * Before submitting the function will check if any fields are null
   */
  public submit(){
    if (this.providerNum != "" && this.password != ""){
      this.providerBlank = false;
      this.passwordBlank = false;
      this.auth.login(this.providerNum.toUpperCase(), this.password);
    } else {
      this.providerBlank = this.providerNum == "";
      this.passwordBlank = this.password == "";
    }
  }

  /**
   * Close the terms and conditions modal window
   */
  public closeTerms(){
    this.termsAndCond = false;
  }

  /**
   * Open the terms and conditions modal window
   */
  public openTerms(){
    this.termsAndCond = true;
  }

}
