<app-navbar></app-navbar>
<div class="default-page-container">
  <div class="default-page-internal remittance-view-internal">
    <div class="back-btn" routerLink="/remittance">
      <img src="../../../assets/Iconography/BackPurple.svg">
      <span class="default-heading2 back-txt">Back</span>
    </div>
    <div class="default-heading default-heading-separator">{{this.utils.getDate(claim.created) | date:'dd/MM/yyy'}}<div class="status-pill" [ngClass]="{'status-pill-submitted': claim.state == 'submitted', 'status-pill-in-review': claim.state == 'in_review', 'status-pill-approved': claim.state == 'approved', 'status-pill-rejected': claim.state == 'rejected'}">{{utils.getState(claim.state)}}</div></div>
    <div *ngIf="claim.bills !== undefined && claim.bills.length > 0" class="default-heading2 invoice-submission-heading-remittance">Direct Bills</div>
    <div *ngIf="claim.bills !== undefined && claim.bills.length <= 0" class="default-heading2 invoice-submission-heading-remittance">Invoices</div>
    <div *ngIf="claim.bills !== undefined && claim.bills.length > 0" class="direct-billing-container">
      <table class="direct-billing-table">
        <thead class="default-table-header">
        <tr>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Invoice No.</th>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Date</th>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Membership No.</th>
          <th class="table-header direct-billing-header direct-billing-cell-small">IRN</th>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Provider No.</th>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Item No.</th>
          <th class="table-header direct-billing-header direct-billing-cell-normal">Fee</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let item of claim.bills; let i = index">
          <td class="direct-billing-row direct-billing-display direct-billing-cell direct-billing-cell-right-border">
            <div class="direct-billing-text">{{item.invoiceNum}}</div>
          </td>
          <td class="direct-billing-row direct-billing-display direct-billing-cell direct-billing-cell-right-border">
            <div class="direct-billing-text">{{this.utils.getDate(item.date) | date:'dd/MM/yyy'}}</div>
          </td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-display direct-billing-cell direct-billing-cell-right-border"><div class="direct-billing-text">{{item.membershipNum}}</div></td>
          <td class="direct-billing-row direct-billing-cell-small direct-billing-display direct-billing-cell direct-billing-cell-right-border"><div class="direct-billing-text">{{item.irn}}</div></td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-display direct-billing-cell direct-billing-cell-right-border"><div class="direct-billing-text">{{item.providerNum}}</div></td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-display direct-billing-cell direct-billing-cell-right-border"><div class="direct-billing-text">{{item.itemNum}}</div></td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-display direct-billing-cell"><div class="direct-billing-text">{{item.fee | currency: 'USD'}}</div></td>
        </tr>
        </tbody>
        <tfoot>
        <tr>
          <td class="direct-billing-row direct-billing-cell-normal"></td>
          <td class="direct-billing-row direct-billing-cell-normal"></td>
          <td class="direct-billing-row direct-billing-cell-normal"></td>
          <td class="direct-billing-row direct-billing-cell-small"></td>
          <td class="direct-billing-row direct-billing-cell-normal"></td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-total-text">Total</td>
          <td class="direct-billing-row direct-billing-cell-normal direct-billing-total-value">{{this.utils.calculateTotal(claim.bills) | currency: 'USD'}}</td>
        </tr>
        </tfoot>
      </table>
    </div>
    <hr *ngIf="claim.claimFiles.length > 0" class="default-bar">
    <div *ngIf="claim.claimFiles.length > 0" class="submitted-file-container default-heading-separator">
      <div *ngFor="let cf of claim.claimFiles" class="submitted-file-row" (click)="clickClaimFile(cf)">{{utils.getFilename(cf.filename)}}</div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<div *ngIf="invoiceView" class="default-modal" [@fadeInOnEnter] [@fadeOutOnLeave]>
  <div class="default-modal-backdrop" (click)="closeInvoice()"></div>
  <div class="default-modal-card file-upload-image">
    <div class="default-modal-exit no-select" (click)="closeInvoice()"><img class="default-modal-exit-cross" src="../../../assets/Iconography/CrossWhite.svg"></div>
    <app-file-viewer [media]="focus" style="width: 100%; height: 100%"></app-file-viewer>
  </div>
</div>
