import { Injectable } from '@angular/core';
import {PropertiesService} from "./properties.service";
import {Observable} from "rxjs";
import {HttpClient, HttpEvent, HttpHeaders, HttpRequest} from "@angular/common/http";
import {Cookie} from "ng2-cookies/ng2-cookies";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor(
    public prop: PropertiesService,
    private http: HttpClient,
    public auth: AuthService) { }

  /**
   * Upload an invoice from a provider
   *
   * @param formData
   * @private
   */
  private uploadProvider(formData): Observable<HttpEvent<any>> {
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.mediaApi;
    const req = new HttpRequest('POST', uri, formData, {
      headers: headers,
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  /**
   * Upload an pdf or image from the admin portal
   *
   * @param formData
   * @private
   */
  private uploadAdmin(formData): Observable<HttpEvent<any>> {
    let headers = new HttpHeaders()
    let uri = this.prop.mediaApi + "/admin";
    const req = new HttpRequest('POST', uri, formData, {
      headers: headers,
      reportProgress: true,
      responseType: 'json'
    });
    return this.http.request(req);
  }

  /**
   * General upload function where data will be marshalled between admin and provider depending ont he user state
   *
   * @param formData
   */
  public upload(formData): Observable<HttpEvent<any>> {
    if (this.auth.admin){
      return this.uploadAdmin(formData);
    } else {
      return this.uploadProvider(formData);
    }
  }

  /**
   * Delete an invoice uploaded by a provider
   *
   * @param id
   * @private
   */
  private deleteProvider(id){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.mediaApi + "/delete/" + id;
    return this.http.delete(uri,{headers: headers});
  }

  /**
   * Delete an image or PDF uploaded by an admin user
   *
   * @param id
   * @private
   */
  private deleteAdmin(id){
    let headers = new HttpHeaders()
    let uri = this.prop.mediaApi + "/admin/" + id;
    return this.http.delete(uri,{headers: headers});
  }

  /**
   * General delete function where data will be marshalled between admin and provider depending ont he user state
   *
   * @param id
   */
  public delete(id){
    if (this.auth.admin){
      return this.deleteAdmin(id);
    } else {
      return this.deleteProvider(id);
    }
  }

  /**
   * Allow a provider to access a media file
   *
   * @param filename
   * @private
   */
  private getProvider(filename){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.mediaApi + "/filename/" + filename;
    return this.http.get(uri,{headers: headers, responseType: "blob"});
  }

  /**
   * Allow an admin user to access a media file
   *
   * @param filename
   * @private
   */
  private getAdmin(filename){
    let headers = new HttpHeaders()
    let uri = this.prop.mediaApi + "/admin/filename/" + filename;
    return this.http.get(uri,{headers: headers, responseType: "blob"});
  }

  /**
   * General get where data will be marshalled depending on if the user is an admin or provider.
   *
   * @param filename
   */
  public get(filename){
    if (this.auth.admin){
      return this.getAdmin(filename);
    } else {
      return this.getProvider(filename);
    }
  }


}
