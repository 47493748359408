import { Injectable } from '@angular/core';
import {PropertiesService} from "./properties.service";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Cookie} from "ng2-cookies/ng2-cookies";

@Injectable({
  providedIn: 'root'
})
export class ClaimsService {

  public remittance:any = [];

  public claim: any;

  public page = 1;

  public size = 20;

  public count = 0;

  public query = "";

  public filterDateStart = null;

  public filterDateEnd = null;

  public filterType = "any";

  public filterStatus = "any";

  public filterError = false;

  public filterPastFutureError = false;

  constructor(public prop: PropertiesService, private http: HttpClient) { }

  /**
   * Convert the date to ISO string
   *
   * @param d
   * @private
   */
  private convertDate(d: Date){
    try{
      return d.toISOString();
    } catch (e){
      var date = new Date(d);
      return date.toISOString();
    }
  }

  /**
   * Generate an empty claim object
   *
   * @private
   */
  private getClaimObj(){
    return {
      "type": "",
      "state": "",
      "providerId": "",
      "providerName": "",
      "claimFiles": [],
      "remittanceFiles": [],
      "remittanceNote": "",
      "bills": []
    }
  }

  /**
   *Convert all the dates to ISO strings
   *
   * @param bills
   * @private
   */
  private updateDates(bills){
    var update = [];
    for (var bill of bills){
      bill.date = this.convertDate(bill.date);
      update.push(bill);
    }
    return update;
  }

  /**
   * Normalize all the bill strings to be ready for the backend
   *
   * @param bills
   * @private
   */
  private updateFee(bills){
    var update = [];
    for (var bill of bills){
      bill.fee = bill.fee.replace(/(?!\.)\D/g, '');
      update.push(bill);
    }
    return update;
  }

  /**
   * Create a claim with the server backend
   *
   * @param claim
   * @private
   */
  private createClaim(claim){
    let headers = new HttpHeaders()
      .set('Content-type', 'application/json; charset=utf-8')
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.claimApi;
    return this.http.post(uri, claim, {headers: headers});
  }

  /**
   * Request all the claims for a given user
   *
   */
  public getClaims(){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.claimApi + "?page=" + this.page + "&size=" + this.size;
    return this.http.get(uri, {headers: headers});
  }

  /**
   * Get a specific claim from the backend
   *
   * @param id
   */
  public getClaim(id){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.claimApi + "/get/" + id;
    return this.http.get(uri, {headers: headers});
  }

  /**
   * Get all the claims in the submitted
   *
   * @param id
   */
  public getClaimSubmitted(id){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.claimApi + "/submitted/" + id;
    return this.http.get(uri, {headers: headers});
  }

  /**
   * Count the total number of claims
   *
   */
  public countClaims(){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.claimApi + "/count";
    return this.http.get(uri, {headers: headers});
  }

  /**
   * Get all the claims with the filter inputs
   *
   * @param dateFrom
   * @param dateTo
   * @param type
   * @param status
   */
  public getClaimsAdmin(dateFrom, dateTo, type, status){
    let headers = new HttpHeaders()
    let uri = this.prop.claimApi + "/admin" + "?page=" + this.page + "&size=" + this.size + "&query=" + this.query;
    let params = {
      DateFrom: dateFrom,
      DateTo: dateTo,
      Type: type,
      Status: status
    };
    return this.http.post(uri, params,{headers: headers});
  }

  /**
   * Get a claim specific claim from the admin portal
   *
   * @param id
   */
  public getClaimAdmin(id){
    let headers = new HttpHeaders();
    let uri = this.prop.claimApi + "/admin/" + id;
    return this.http.get(uri, {headers: headers});
  }

  /**
   * Save an updated claim to the server
   *
   * @param claim
   */
  public saveClaimAdmin(claim){
    let headers = new HttpHeaders();
    let uri = this.prop.claimApi + "/admin/" + claim.id;
    return this.http.put(uri, claim, {headers: headers});
  }

  /**
   * Save claim file from a povider
   *
   * @param claim
   */
  public saveClaimFiles(claim){
    let headers = new HttpHeaders()
    .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.claimApi + "/media/" + claim.id;
    return this.http.put(uri, claim, {headers: headers});
  }

  /**
   * Count the total number of claims with the filter params
   *
   * @param dateFrom
   * @param dateTo
   * @param type
   * @param status
   */
  public countClaimsAdmin(dateFrom, dateTo, type, status){
    let headers = new HttpHeaders();
    let uri = this.prop.claimApi + "/admin/count" + "?query=" + this.query;
    let params = {
      DateFrom: dateFrom,
      DateTo: dateTo,
      Type: type,
      Status: status
    };
    return this.http.post(uri, params,{headers: headers});
  }

  /**
   * Generate a report between two specific dates
   *
   * @param startDate
   * @param endDate
   */
  public generateReport(startDate, endDate){
    let headers = new HttpHeaders();
    let uri = this.prop.claimApi + "/admin/report";
    let query = {
      Start: startDate,
      End: endDate
    };
    return this.http.post(uri, query, {headers: headers});
  }

  /**
   * Submit invoices uploaded by the user
   *
   * @param files
   * @param providerId
   * @param providerName
   */
  public submitInvoice(files, providerId, providerName){
    var claim = this.getClaimObj();
    claim.type = "invoice";
    claim.state = "submitted";
    claim.providerId = providerId;
    claim.providerId = providerName;
    claim.claimFiles = files;
    return this.createClaim(claim);
  }

  /**
   * Submit the direct bills uploaded by the user
   *
   * @param bills
   * @param providerId
   * @param providerName
   */
  public submitDirect(bills, providerId, providerName){
    var claim = this.getClaimObj();
    claim.type = "direct";
    claim.state = "submitted";
    claim.providerId = providerId;
    claim.providerId = providerName;
    claim.bills = this.updateFee(this.updateDates(JSON.parse(JSON.stringify(bills))));
    return this.createClaim(claim);
  }

  /**
   * Count the total number of claims with the filter params
   *
   * @param claimId
   */
  public getZip(claimId){
    let headers = new HttpHeaders();
    let uri = this.prop.claimApi + "/admin/zip/" + claimId;
    return this.http.get(uri, {headers: headers, responseType: "blob"});
  }


}
