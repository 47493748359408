<div *ngIf="alert.modal" class="default-modal" [@fadeInOnEnter] [@fadeOutOnLeave]>
  <div class="default-modal-backdrop" (click)="close()"></div>
  <div class="default-modal-card alert-card" [ngClass]="{'alert-card-extra-height': alert.state == 3}">
    <div class="default-modal-exit no-select" (click)="close()"><img class="default-modal-exit-cross" src="../../../assets/Iconography/CrossWhite.svg"></div>
    <div *ngIf="alert.state == 0" class="alert-hero">
      <div class="success-hero"><img src="../../../assets/Iconography/CheckSuccess.svg"></div>
      <div class="default-heading3 alert-main-message">{{alert.message}}</div>
    </div>
    <div *ngIf="alert.state == 1" class="alert-hero">
      <div class="error-hero"><img class="error-icon" src="../../../assets/Iconography/Error.svg"></div>
      <div class="default-heading3 alert-main-message">{{alert.message}}</div>
    </div>
    <div *ngIf="alert.state == 2" class="alert-hero">
      <div class="warning-hero"><img class="error-icon" src="../../../assets/Iconography/Warning.svg"></div>
      <div class="default-heading3 alert-main-message">{{alert.message}}</div>
    </div>
    <div *ngIf="alert.state == 3" class="alert-hero alert-hero-extra-height">
      <div class="warning-hero"><img class="error-icon" src="../../../assets/Iconography/Warning.svg"></div>
      <div class="default-heading3 alert-main-message" style="margin-top: 10px; font-weight: 500">Due to inactivity your session will expire soon.<br><br>Click anywhere to continue.</div>
      <button class="default-btn default-btn-primary default-submit-btn" (click)="close()" style="margin-top: 23px;">Ok</button>
    </div>
  </div>
</div>

