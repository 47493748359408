import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import { ConfigurationService } from "../../services/configuration.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  @Input() login = false;
  public websiteURL;
  public aboutUs;
  public faq;
  public oshc_home;
  public ovhc_home;
  public contactUs;
  public privacyURL;
  public termsOfUseURL;
  public providerRelationTel;
  public officeHrs;  

  constructor(public authService: AuthService, private appConfigService: ConfigurationService) { 
    this.websiteURL = this.appConfigService.WebsiteURL;
    this.aboutUs = this.appConfigService.AboutUs;
    this.faq = this.appConfigService.FAQ;
    this.oshc_home = this.appConfigService.OSHC_home;
    this.ovhc_home = this.appConfigService.OVHC_home;
    this.contactUs = this.appConfigService.ContactUs;
    this.privacyURL = this.appConfigService.PrivacyURL;
    this.termsOfUseURL = this.appConfigService.TermsOfUseURL;
    this.providerRelationTel = this.appConfigService.ProviderRelationTel;
    this.officeHrs = this.appConfigService.OfficeHrs;
  }

  ngOnInit(): void {
  }

}
