import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Cookie} from "ng2-cookies/ng2-cookies";
import {PropertiesService} from "./properties.service";
import {AuthService} from "./auth.service";
import {AlertService} from "./alert.service";
import {UtilitiesService} from "./utilities.service";

@Injectable({
  providedIn: 'root'
})
export class RemittanceService {

  public remittances:any = [];

  public remittance: any;

  public page = 1;

  public size = 20;

  public count = 0;

  public pageNumbers = [];

  public loadingPage = false;

  constructor(
    public prop: PropertiesService,
    private http: HttpClient,
    public auth: AuthService,
    public alert: AlertService,
    public utils: UtilitiesService) { }

  /**
   * Get remittance document with a specific ID
   *
   * @param formArchiveId
   */
  public get(formArchiveId){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.remittanceApi + "/" + formArchiveId;
    return this.http.get(uri,{headers: headers});
  }

  /**
   * Get all remittance document for a specific page
   *
   */
  public getPage(){
    this.loadingPage = true;
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.remittanceApi + "?page=" + this.page + "&size=" + this.size;
    this.http.get(uri,{headers: headers})
      .subscribe(
        data => {
          this.loadingPage = false;
          this.remittances = data;
        }, err => {
          this.loadingPage = false;
          this.auth.verifyUser();
        }
      )
  }

  /**
   * Count all the remittance documents
   */
  public countRemittances(){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.remittanceApi + "/count";
    this.http.get(uri,{headers: headers})
      .subscribe(
        data => {
          this.count = +data;
          this.pageNumbers = this.utils.calculatePageNumbers(this.page, this.size, this.count);
        }, err => {
          this.auth.verifyUser();
        }
      )
  }

}
