import { Component, OnInit } from '@angular/core';
import {UtilitiesService} from "../../services/utilities.service";
import * as uuid from 'uuid';
import {MediaService} from "../../services/media.service";
import {AuthService} from "../../services/auth.service";
import {ClaimsService} from "../../services/claims.service";
import {fadeInOnEnterAnimation, fadeOutOnLeaveAnimation} from "angular-animations";
import {NavService} from "../../services/nav.service";
import {interval} from "rxjs";
import {AlertService} from "../../services/alert.service";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-admin-remittance',
  templateUrl: './admin-remittance.component.html',
  styleUrls: ['./admin-remittance.component.scss'],
  animations: [
    fadeInOnEnterAnimation({ duration: 300}),
    fadeOutOnLeaveAnimation({ duration: 300})
  ]
})
export class AdminRemittanceComponent implements OnInit {

  public claim:any = {
    id: 1,
    remittanceFiles: [],
    claimFiles: [
      {
        "id": 2,
        "filename": "string",
        "type": "string",
        "userId": "string"
      },
      {
        "id": 10,
        "filename": "string",
        "type": "string",
        "userId": "string"
      },
      {
        "id": 11,
        "filename": "string",
        "type": "string",
        "userId": "string"
      }
    ],
    bills: [
      {
        id: 3,
        invoiceNum: "123456",
        date: "2021-02-14T23:30:09.320Z",
        membershipNum: "123456",
        irn: 0,
        providerNum: "1234567",
        itemNum: "123456",
        fee: 0
      }
    ],
    lastEdited: new Date().toISOString()
  };

  public files: Array<File> = [];

  public loading = [];

  public processedMedia: any = [];

  public focus: any;

  public invoiceView = false;

  public saveTrigger: any;

  public saveAnimation = false;

  public saving = false;

  constructor(public utils: UtilitiesService,
              public mediaService: MediaService,
              public auth: AuthService,
              public claimsService: ClaimsService,
              public navService: NavService,
              public alert: AlertService,
              public datePipe: DatePipe,
              public router: Router) {
    this.navService.navTab = -1;
    this.claim = this.claimsService.claim;
    if (this.claim == undefined){
      this.router.navigate(['/admin/remittance'])
    }
  }

  ngOnInit(): void {
  }


  /**
   * Open the invoice modal window
   */
  public openInvoice(){
    this.invoiceView = true;
  }

  /**
   * Close the invoice modal window
   */
  public closeInvoice(){
    this.invoiceView = false;
  }

  /**
   * Click claim file and open a modal generated for the focussed claim file
   *
   * @param {any} cf - claim file object
   */
  public clickClaimFile(cf){
    this.focus = cf;
    this.openInvoice();
  }

  /**
   * Reload claim from the server
   */
  public refreshClaim(){
    this.claimsService.getClaimAdmin(this.claim.id)
      .subscribe(
        data => {
          this.claim = data;
        },
        err => {
          this.auth.verifyAdmin();
        }
      );
  }

  /**
   * Save the currently displayed claim object to the server.
   */
  public save(){
    this.saving = true;
    var processedFiles = [];
    var loading = false;
    for (var i=0; i < this.processedMedia.length; i++){
      loading = this.loading[i].loading || loading;
      if (this.processedMedia[i].id >= 0){
        processedFiles.push(this.processedMedia[i])
      }
    }
    this.claim.remittanceFiles = processedFiles;
    this.claimsService.saveClaimAdmin(this.claim)
      .subscribe(
        data => {
          this.refreshClaim();
          this.saveSuccess();
          this.saving = false;
        },
        err => {
          this.alert.error("An error occurred saving your file");
          this.saving = false;
        }
      );
  }

  /**
   * Create an interval of 3.5 seconds allowing feedback to be displayed to the user. This will only be called after
   * a successful save operation.
   */
  private saveSuccess(){
    if (this.saveTrigger != undefined){
      this.saveTrigger.unsubscribe();
    }
    this.saveAnimation = true;
    this.saveTrigger = interval(3500)
      .subscribe(() => {
        this.saveAnimation = false;
        this.saveTrigger.unsubscribe();
      });
  }

  /**
   * Downloads all of the claim files to the users computer.
   */
  public downloadAllCf(){
    if (this.claim.claimFiles.length >= 10){
      this.utils.downloadZip(this.claim.id, this.claim.providerName)
    } else {
      for (let cf of this.claim.claimFiles){
        this.utils.download(cf);
      }
    }
  }

  /**
   * Generate a CSV file will all of the submitted direct bill items.
   */
  public generateCSV(){
    var d = this.claim;
    this.utils.downloadCsv(d);
  }


}
