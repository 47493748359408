import { Injectable } from '@angular/core';
import {CurrencyPipe, DatePipe} from "@angular/common";
import {MediaService} from "./media.service";
import {ClaimsService} from "./claims.service";
import {AlertService} from "./alert.service";

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  public downloadingCf = false;

  constructor(
    public currencyPipe: CurrencyPipe,
    public mediaService: MediaService,
    public claimService: ClaimsService,
    public datePipe: DatePipe,
    public alert: AlertService) { }

  /**
   * Verify that an HTML element is a child to a parent element
   * @param parent
   * @param child
   */
    public isDescendant(parent: HTMLElement, child: HTMLElement){
    var node = child.parentNode;
    while (node != null){
      if (node === parent){
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  /**
   * Calculate the total of a group of fees input as direct bill line items
   *
   * @param items
   */
  public calculateTotal(items: any){
    var total = 0;
    for (var i=0; i < items.length; i++){
      try{
        if (Number.isFinite(items[i].fee)){
          total += items[i].fee
        } else {
          var x = parseFloat(items[i].fee.replace(/(?!\.)\D/g, ''));
          if (!Number.isNaN(x)){
            total += x;
          }
        }
      } catch(e){}
    }
    return total;
  }

  /**
   * General function to convert a string to a data. All dates will be considered to be in ISO format.
   *
   * @param d
   */
  public getDate(d){
    try{
      if (!d.endsWith("Z")){
        d = d + "Z";
      }
      return new Date(d);
    } catch (e){
      return new Date()
    }
  }

  /**
   * A function to get the date object when the string is not in ISO format.
   *
   * @param d
   */
  public getSydneyTime(d){
    return new Date(d);
  }

  /**
   * Generate the display string for an enum state
   *
   * @param state
   */
  public getState(state){
    switch (state) {
      case "submitted": {
        return "Submitted"
      } case "in_review": {
        return "In Review"
      } case "approved": {
        return "Approved"
      } case "rejected": {
        return "Rejected"
      }
    }
  }

  /**
   * Calculate the page numbers associated with a given page size and count
   *
   * @param page
   * @param pageSize
   * @param count
   */
  public calculatePageNumbers(page, pageSize, count){
    var pageNums = [];
    var maxPage = Math.ceil(count / pageSize);
    var diff;
    if (maxPage - 5 < page){
      diff = -9 + (maxPage - page)
    } else {
      diff = -4;
    }
    while (pageNums.length < 10){
      var current = page + diff;
      if (current > maxPage){
        break;
      } else if (current > 0){
        pageNums.push(current)
      }
      diff += 1;
    }
    return pageNums
  }

  public verifyCharactersStartsWith(input: string){
    if (input.startsWith("-")){
      return false;
    }
    return true;
  }

  public verifyCharacters(input: string){
    if (input.indexOf("+") > -1){
      return false;
    } else if (input.indexOf("=") > -1){
      return false;
    } else if (input.indexOf("@") > -1){
      return false;
    } else if (input.indexOf("\"") > -1){
      return false;
    } else if (input.indexOf("'") > -1){
      return false;
    } else if (input.indexOf(",") > -1){
      return false;
    }
    return true;
  }

  /**
   * Verify that a free text input is below a certain character level
   *
   * @param value
   * @param max
   */
  public verifyFreeText(value, max){
    try{
      if (!this.verifyCharacters(value) || !this.verifyCharactersStartsWith(value)){
        return false;
      }
      if (value == "" || value.length > max){
        return false;
      }
      return true;
    } catch (e){
      return false;
    }

  }

  /**
   * Verify a date object is between two parameters
   *
   * @param date
   * @param minDate
   * @param maxDate
   */
  public verifyDate(date, minDate, maxDate){
    try{
      date = new Date(date);
    } catch (e){}
    try{
      if (date == null || date == ""){
        return false;
      } else if (minDate != null && date < minDate){
        return false;
      } else if (maxDate != null && date > maxDate){
        return false;
      }
      return true;
    } catch (e){
      return false;
    }

  }

  /**
   * Verify that an input is alpha numeric and below a maximum character limit.
   *
   * @param text
   * @param max
   */
  public verifyAlphaNumeric(text, max){
    try{
      text = String(text);
      if (!this.verifyCharacters(text) || !this.verifyCharactersStartsWith(text)){
        return false;
      }
      if (text.match(/^[a-z0-9]+$/i) && text.length <= max) {
        return true;
      } else {
        return false;
      }
    } catch (e){
      return false;
    }

  }

  /**
   * Verify a currency string
   *
   * @param currency
   */
  public verifyCurrency(currency){
    try{
      var clean = currency.replace(/(?!\.)\D/g, '').replace(/^0+/, '');
      if (clean == ""){
        return false;
      }
      this.currencyPipe.transform(clean, 'USD', 'symbol', '2.2');
      return !(!isFinite(clean) || clean > 9999.99 || clean <= 0);
    } catch (e){
      return false;
    }
  }

  /**
   * Verify that a valid IRN number has been submitted
   *
   * @param irn
   */
  public verifyIrn(irn){
    try{
      if (irn.length > 2 || irn == "" || Number.isNaN(+irn) || irn <= 0){
        return false;
      }
      return true;
    } catch (e){
      return false;
    }
  }

  /**
   * Get the filename, a portion of the string will be ignored as it is prefaced with a uuid to prevent file conflicts.
   * @param filename
   */
  public getFilename(filename){
    return filename.substring(37);
  }

  /**
   * Download a blob to the users computer with a given filename
   *
   * @param blob
   * @param filename
   */
  public downloadBlob(blob, filename){
    var a = document.createElement("a");
    document.body.appendChild(a);
    // @ts-ignore
    a.style = "display: none";
    let url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  /**
   * Download a media object by recalling the bytes from the server and initiating a download.
   *
   * @param media
   */
  public download(media){
    this.mediaService.get(media.filename)
      .subscribe(
        data => {
          this.downloadBlob(data, media.filename.substring(37));
        }, err => {
        }
      );
  }

  /**
   * Download a zip file for the .
   *
   * @param media
   */
  public downloadZip(claimId, zipFilename){
    this.downloadingCf = true;
    this.claimService.getZip(claimId)
      .subscribe(
        data => {
          this.downloadingCf = false;
          this.downloadBlob(data, zipFilename + ".zip");
        }, err => {
          this.downloadingCf = false;
          this.alert.error("An error occurred downloading your zip file");
        }
      );
  }

  /**
   * Download a zip file for the .
   *
   * @param media
   */
  public downloadZipBlank(claimId, zipFilename){
    return this.claimService.getZip(claimId);
  }

  /**
   * Register a keydown event and ensure that event is a number key
   *
   * @param event
   */
  public numberKeydown(event){
    if (!isFinite(event.key) || event.key == " "){
      if (
        event.key != "Backspace" &&
        event.key != "Delete" &&
        event.key != "Tab" &&
        event.key != "ArrowLeft" &&
        event.key != "ArrowRight" &&
        !event.ctrlKey){
        event.preventDefault();
      }
    }
  }

  /**
   * Register a keydown event for IRN inputs
   *
   * @param event
   */
  public irnKeyDown(event){
    this.numberKeydown(event);
  }

  /**
   * Register an item number keydown event
   *
   * @param event
   */
  public itemNumKeyDown(event){
    this.numberKeydown(event);
  }

  /**
   * Register a membership number keydown
   *
   * @param event
   */
  public membershipNumberKeyDown(event){
    this.numberKeydown(event);
  }

  /**
   * Register a fee down keydown event and prevent invalid characters
   *
   * @param event
   */
  public feeKeyDown(event){
    if (!isFinite(event.key) || event.key == " "){
      if (
        event.key != "Backspace" &&
        event.key != "Delete" &&
        event.key != "Tab" &&
        event.key != "ArrowLeft" &&
        event.key != "ArrowRight" &&
        event.key != "Enter" &&
        event.key != "," &&
        event.key != "." &&
        event.key != "$"){
        event.preventDefault();
      }
    }
  }

  /**
   * Take a date string input as ISO format and return in dd/MM/yyyy format
   *
   * @param d
   */
  public getDateFormatted(d){
    try{
      if (!d.toLowerCase().endsWith("Z")){
        d = d + "Z";
      }
      var date = new Date(d);
      return this.datePipe.transform(date, 'dd/MM/yyyy');
    } catch (e){
      return d;
    }

  }

  /**
   * Input a date string in ISO format and return the time in hh:MM
   *
   * @param d
   */
  public getTime(d){
    try{
      if (!d.toLowerCase().endsWith("z")){
        d = d + "Z";
      }
      var date = new Date(d);
      return this.datePipe.transform(date, 'hh:mm:ss a');
    } catch (e){
      return d;
    }

  }

  /**
   * Return the display status of the state enum
   * @param d
   */
  public getStatus(d){
    if(d.state == "submitted"){
      return "Submitted";
    } else if (d.state == "in_review"){
      return "In Review"
    } else if (d.state == "approved"){
      return "Approved"
    } else {
      return "Rejected";
    }
  }

  /**
   * Transform the an ISO date string to a formatted date in the format yyyy/MM/dd
   * @param d
   * @private
   */
  private getReportDate(d){
    try{
      if (!d.toLowerCase().endsWith("z")){
        d = d + "Z";
      }
      var date = new Date(d);
      return this.datePipe.transform(date, 'yyyy/MM/dd');
    } catch (e){
      var date = new Date(d);
      return this.datePipe.transform(date, 'yyyy/MM/dd');
    }

  }

  /**
   * Download a CSV file for a list of direct bill claim items.
   * @param data
   * @param reportDateStart
   * @param reportDateEnd
   */
  public downloadCSVGroup(data, reportDateStart, reportDateEnd){
    var csv = "Date Created, Time Created, Provider / Practice name, Provider No., Membership No., IRN, Invoice No., Date of Service, Item No., Fee, Status";
    for (var d of data){
      var bills = d.bills;
      for (var bill of bills){
        csv += "\r\n";
        csv += this.getDateFormatted(d.created) + ",";
        csv += this.getTime(d.created) + ",";
        csv += d.providerName + ",";
        csv += bill.providerNum + ",";
        csv += bill.membershipNum + ",";
        csv += bill.irn + ",";
        csv += bill.invoiceNum + ",";
        csv += this.getDateFormatted(bill.date) + ",";
        csv += bill.itemNum + ",";
        csv += bill.fee + ",";
        csv += this.getStatus(d);
      }
    }
    var blob = new Blob([csv], {type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = this.getReportDate(reportDateStart) + " To " + this.getReportDate(reportDateEnd) + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  /**
   * Download a CSV file for a specific claim.
   * @param claim
   */
  public downloadCsv(claim){
    var d = claim;
    var csv = "Date Created, Time Created, Provider / Practice name, Provider No., Membership No., IRN, Invoice No., Date of Service, Item No., Fee, Status";
    var bills = d.bills;
    for (var bill of bills){
      csv += "\r\n";
      csv += this.getDateFormatted(d.created) + ",";
      csv += this.getTime(d.created) + ",";
      csv += d.providerName + ",";
      csv += bill.providerNum + ",";
      csv += bill.membershipNum + ",";
      csv += bill.irn + ",";
      csv += bill.invoiceNum + ",";
      csv += this.getDateFormatted(bill.date) + ",";
      csv += bill.itemNum + ",";
      csv += bill.fee + ",";
      csv += this.getStatus(d);
    }
    var blob = new Blob([csv], {type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = d.providerName.trim() + " - " + this.getDateFormatted(d.created) + '.csv';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }


}
