import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {PropertiesService} from "./properties.service";
import {Router} from "@angular/router";
import {Cookie} from "ng2-cookies/ng2-cookies";
import {AlertService} from "./alert.service";
import {interval} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public userId = "123456";

  public admin = false;

  public token: any = {};

  public error = false;

  public errorMsg = "";

  public loading = false;

  public activityTrigger: any;

  public activity = false;

  public activityFails = 0;

  public timeout = undefined;

  constructor(
    public prop: PropertiesService,
    private http: HttpClient,
    public router: Router,
    public alert: AlertService) { }


  public getTimeoutWarningTrigger(){
    try{
      if (this.timeout == undefined){
        return 15;
      } else if (this.timeout.option == 0){
        return 15;
      } else {
        return 30;
      }
    } catch (e){
      return 15;
    }
  }

  /**
   * Get the expiry date of the token value
   *
   * @param token
   * @private
   */
  private getExpiry(token){
      var expiry = token.expiry;
      if(!expiry.endsWith("Z")){
        expiry = expiry + "Z";
      }
      var date = new Date(expiry);
      var current = new Date();
      var dif = date.getTime() - current.getTime();
      var days = dif / 86400000;
      return days;
    }

    /**
   * Get the expiry date of the token value
   *
   * @param token
   * @private
   */
  private getExpiryInMinutes(token){
      var expiry = token.expiry;
      if(!expiry.endsWith("Z")){
        expiry = expiry + "Z";
      }
      var date = new Date(expiry);
      var current = new Date();
      var dif = date.getTime() - current.getTime();
      return Math.floor((dif/1000)/60);
    }

  /**
   * Process the error token and generate
   *
   * @param token
   * @private
   */
    private processError(token){
      if (token == -5){
        this.alert.error("Sorry! User is not authorised to use this page, please contact 1300 174 538");
      } else {
        this.alert.error("Your username or password is invalid. Please try again.");
      }
    }

  /**
   * Process the login data. This will be called after a login request is made to the backend
   *
   * @param data
   * @private
   */
    private processLogin(data){
      if (data.id == -1){
        this.error = false;
        this.processError(data.token);
        return false;
      } else {
        this.token = data;
        var expiry = this.getExpiry(this.token);
        Cookie.set('access_token', this.token.token, expiry, "/");
        this.admin = false;
        return true;
      }
    }

  /**
   * Check the token data to see if a timeout warning should be raised
   *
   * @param data
   * @private
   */
    private processTimeoutWarning(data){
      if (data.id != -1){
        var minutes = this.getExpiryInMinutes(this.token)
        if (minutes < this.getTimeoutWarningTrigger()){
          this.alert.timeout(this.getTimeoutWarningTrigger());
        }
      }
    }

  /**
   * Start tracking the activity of users.
   *
   * @private
   */
  private startActivityTracking(){
      try{
        if (this.activityTrigger != undefined){
          this.activityTrigger.unsubscribe();
        }
      } catch (e){}
      this.activityTrigger = interval(3000)
        .subscribe(() => {
          this.processActivity(this.activity);
        });
    }

  /**
   * Generate a login request to the server and process the result to see if the user is valid to enter the portal
   *
   * @param providerNum
   * @param password
   */
    public login(providerNum, password){
      this.loading = true;
      let headers = new HttpHeaders();
      let uri = this.prop.authApi + "/login";
      let params = {
        username: providerNum,
        password: password
      };
      this.http.post(uri, params,{headers: headers})
        .subscribe(
          data =>{
            this.loading = false;
            var login = this.processLogin(data);
            if (login){
              this.router.navigate(["/eligibility"]);
              this.startActivityTracking();
              this.setTimeoutToken();
            }
          }, err =>{
            this.loading = false;
            this.error = false;
            this.alert.error("An unexpected error occurred contacting the server");
          }
        )
    }

  /**
   * Process activity of the user on the platform. The user will be logged out if they are inactive for 20 minutes.
   *
   * @param activity
   */
    public processActivity(activity){
      let headers = new HttpHeaders()
            .set('ProviderAuthorization', Cookie.get('access_token'));
      let params = {
        Active: activity
      };
      let uri = this.prop.authApi;
      this.http.post(uri, params, {headers: headers})
        .subscribe(
          data => {
            this.activityFails = 0;
            this.activity = false;
            this.processLogin(data);
            this.processTimeoutWarning(data);
          }, err => {
            this.activityFails += 1;
            if (this.activityFails > 20){
              try{
                if (this.activityTrigger != undefined){
                  this.activityTrigger.unsubscribe();
                }
              } catch (e){}
              this.alert.error("Your session has timed out, please log in again.");
              this.router.navigate(["/"])
            }
          }
        )
    }

  /**
   * Logout a provider from the portal
   */
  public logout_provider(){
      try{
        if (this.activityTrigger != undefined){
          this.activityTrigger.unsubscribe();
        }
      } catch (e){}
      this.deleteToken();
      Cookie.delete("access_token", "/");
      this.router.navigate(["/"]);
    }

  /**
   * Logout an admin user from the provider portal
   *
   */
  public logout_admin(){
      localStorage.clear();
      this.router.navigate(["/admin"])
    }

  /**
   * General logout function to logout admin or user depending on their state.
   *
   */
  public logout(){
      if (this.admin){
        this.logout_admin();
      } else {
        this.logout_provider();
      }
    }

  /**
   * Get the token data from the server
   */
  public get(){
      let headers = new HttpHeaders()
        .set('ProviderAuthorization', Cookie.get('access_token'));
      let uri = this.prop.authApi + "/get";
      this.http.get(uri, {headers: headers})
        .subscribe(
          data =>{
            this.token = data;
            this.setTimeoutToken();
          }, err =>{
            this.alert.error("Your session has timed out, please log in again.");
            this.router.navigate(["/"])
          }
        )
    }

  /**
   * Delete a provider token from the backend
   */
  public deleteToken(){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.authApi;
    this.http.delete(uri, {headers: headers})
      .subscribe(
        data =>{
        }, err =>{
          this.alert.error("An error occurred deleting your token.");
        }
      )
  }

  /**
   * Verify the admin state
   */
  public verifyAdmin(){
      let headers = new HttpHeaders();
      let uri = this.prop.authApi + "/admin";
      this.http.get(uri, {headers: headers})
        .subscribe(
          data =>{
            this.admin = true;
          }, err => {
            this.router.navigate(["/admin"])
          }
        )
    }

  /**
   * Verify the user regardless of if they are admins or general users
   *
   */
  public verifyUser(){
      if (this.admin){
        this.verifyAdmin();
      } else {
        this.get();
      }
    }

  /**
   * Verify the activity of status
   */
  public verifyActivityStatus(){
      if (this.activityTrigger == undefined || this.activityTrigger == null){
        this.startActivityTracking();
      }
    }

  /**
   * Get the timeout for a provider user
   *
   */
  public getTimeoutToken(){
    let headers = new HttpHeaders()
      .set('ProviderAuthorization', Cookie.get('access_token'));
    let uri = this.prop.timeoutApi;
    return this.http.get(uri,{headers: headers});
  }

  /**
   * Set the timeout for a provider user
   *
   */
  public setTimeoutToken(){
    this.getTimeoutToken()
      .subscribe(
        data => {
          this.timeout = data;
        }, err => {
          this.timeout = undefined;
        }
      )
  }


}
