import {AfterContentInit, Component, Input, OnInit} from '@angular/core';
import {AuthService} from "../../services/auth.service";
import * as uuid from 'uuid';
import {RemittanceService} from "../../services/remittance.service";

@Component({
  selector: 'app-file-viewer-remittance',
  templateUrl: './file-viewer-remittance.component.html',
  styleUrls: ['./file-viewer-remittance.component.scss']
})
export class FileViewerRemittanceComponent implements OnInit, AfterContentInit {

  public viewerUUid = uuid.v4();

  @Input() media = {
    formArchiveId: -1,
    FormId: "",
    SubRefType: "",
    FormStatus: "",
    MediaType: "",
    MainRefType: "",
    MainRef: "",
    SubRef: "",
    LetterSubject: "",
    BlobSize: "",
    Direction: "",
    StatusDate: "",
    CreateDateTime: "",
    OperatorName: ""
  };

  public loading = false;

  constructor(
    public remittanceService: RemittanceService,
    public auth: AuthService) { }

  ngOnInit(): void {
  }

  ngAfterContentInit(): void {
    this.loading = true;
    this.remittanceService.get(this.media.formArchiveId)
      .subscribe(
        data => {
          var base64str: any = data;
          var binary = atob(base64str.replace(/\s/g, ''));
          var len = binary.length;
          var buffer = new ArrayBuffer(len);
          var view = new Uint8Array(buffer);
          for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
          }
          var blob = new Blob( [view], { type: "application/pdf" });
          var uri = URL.createObjectURL(blob);
          var el = document.getElementById(this.viewerUUid);
          el.setAttribute("src", uri);
          this.loading = false;
        }, err => {
          this.loading = false;
          this.auth.verifyUser();
        }
      );
  }

}
