import { Component, OnInit } from '@angular/core';
import {NavService} from "../../services/nav.service";
import {AuthService} from "../../services/auth.service";
import {EligibilityService} from "../../services/eligibility.service";
import {AlertService} from "../../services/alert.service";
import {UtilitiesService} from "../../services/utilities.service";
import {DatePipe} from "@angular/common";
import {TimeoutService} from "../../services/timeout.service";

@Component({
  selector: 'app-eligibility-check',
  templateUrl: './eligibility-check.component.html',
  styleUrls: ['./eligibility-check.component.scss']
})
export class EligibilityCheckComponent implements OnInit {

  public loading = false;

  public membershipNum = "";

  public errorMembership = false;

  public membershipBlank = false;

  public irnBlank = false;

  public dobBlank = false;

  public irn = "";

  public errorIrn = false;

  public date: any = null;

  public errorDate = false;

  public submitted = false;

  public success = false;

  public membershipData: any;

  public maxDate = new Date();

  public tooltipLeft = 0;

  public tooltipTop = 0;

  public maxDateVal = "";

  public eligibilityErrorMessage = "Status: Member is NOT Eligible";

  constructor(
    public navService: NavService,
    public auth: AuthService,
    public eligibilityService: EligibilityService,
    public alert: AlertService,
    public utils: UtilitiesService,
    public timeoutService: TimeoutService,
    public datePipe: DatePipe) {
    this.navService.navTab = 0;
    this.auth.get();
    this.maxDateVal = this.datePipe.transform(this.maxDate, 'yyyy-MM-dd');
    this.auth.verifyActivityStatus();
  }

  ngOnInit(): void {
  }

  /**
   * Processes the data from the CBHS server after an eligibility check is performed. The object returned will be
   * directly returned from the CBHS eligibility service.
   *
   * The response status from the object will be processed as follows:
   * STATUS = 0 : Valid membership
   * STATUS = 3 : Invalid membership - error message rendered from the server
   * STATUS != 0 && STATUS != 3 : Invalid membership - Member is not eligible
   *
   * Error: Member is not eligible
   *
   * @param {any} data: returned error from the CBHS eligibility service.
   *
   */
  private processData(data){
    try{
      if (data.status == 0){
        this.membershipData = data;
        this.submitted = true;
        this.success = true;
      } else if (data.status == 3) {
        this.submitted = true;
        this.success = false;
        this.eligibilityErrorMessage = data.errorMessage;
      } else {
        this.membershipData = data;
        this.submitted = true;
        this.success = false;
        this.eligibilityErrorMessage = "Status: Member is NOT Eligible";
      }
    } catch (e){
      this.membershipData = data;
      this.submitted = true;
      this.success = false;
      this.eligibilityErrorMessage = "Status: Member is NOT Eligible";
    }
  }

  /**
   * Submit the data for membership verification
   */
  public submit(){
    if (this.verifyInputs()){
      this.loading = true;
      this.eligibilityService.check(this.membershipNum, this.irn, this.date, this.auth.token.providerNum)
        .subscribe(
          data => {
            this.loading = false;
            this.processData(data);
          }, err =>{
            this.loading = false;
            this.submitted = true;
            this.success = false;
            this.alert.error("Sorry! This service is not available now, please try again later.");
          }
        )
    }
  }

  /**
   * Show the tooltip related to the IRN number
   */
  public showTooltip(){
    var el = <HTMLElement>document.getElementById("tooltip");
    var anchor = <HTMLElement>document.getElementById("anchor");
    var rect = anchor.getBoundingClientRect();
    this.tooltipLeft = rect.right + 10;
    this.tooltipTop = rect.top - 150;
    el.setAttribute('data-show', '');
  }

  /**
   * Hide the tooltip related to the IRN number
   */
  public hideTooltip(){
    var el = <HTMLElement>document.getElementById("tooltip");
    el.removeAttribute('data-show');
  }

  /**
   * Verify the membership number input by the user
   */
  public verifyMembership(){
    this.errorMembership = false;
    this.membershipBlank = false;
    if (this.membershipNum.length > 9){
      this.errorMembership = true;
    } else if (this.membershipNum == ""){
      this.errorMembership = true;
      this.membershipBlank = true;
    }
  }

  /**
   * Verify the irn number input by the user
   */
  public verifyIrn(){
    this.errorIrn = false;
    this.irnBlank = false;
    if (this.irn == ""){
      this.errorIrn = true;
      this.irnBlank = true;
    } else if (this.irn.length > 2 || Number.isNaN(+this.irn) || +this.irn <= 0){
      this.errorIrn = true;
    } else if (+this.irn < 10){
      var x = +this.irn;
      this.irn = "0" + x;
    }
  }

  /**
   * Verify the date input by the user.
   */
  public verifyDate(){
    this.errorDate = false;
    this.dobBlank = false;
    var earliest = new Date("01/01/1900");
    var latest = new Date();
    var currentTime;
    try{
      currentTime = new Date(this.date);
    } catch (e){
      currentTime = this.date;
    }
    if (this.date == null || this.date == ""){
      this.errorDate = true;
      this.dobBlank = true;
    } else if (earliest.getTime() > currentTime.getTime() || latest.getTime() < currentTime.getTime()){
      this.errorDate = true;
    }
  }

  /**
   * Verify all of the input fields
   *
   * @return {boolean} valid inputs
   */
  public verifyInputs(){
    this.verifyMembership();
    this.verifyIrn();
    this.verifyDate();
    return !this.errorMembership && !this.errorIrn && !this.errorDate;
  }

  /**
   * Clear all the input fields
   */
  public clear(){
    this.submitted = false;
    this.errorMembership = false;
    this.membershipBlank = false;
    this.errorIrn = false;
    this.irnBlank = false;
    this.errorDate = false;
    this.dobBlank = false;
    this.membershipNum = "";
    this.irn = "";
    this.date = null;
  }

}
