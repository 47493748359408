<app-navbar></app-navbar>
<div class="default-page-container">
  <div class="default-page-internal invoice-page footer-spacing">
    <div class="default-heading default-heading-sub-separator">Submit Invoice</div>
    <div class="default-paragraph-small">Please upload your invoice/s (maximum size of 5MB per file)</div>
    <div class="table-container invoice-submission-table-separator">
      <label *ngIf="files.length <= 0" [for]="'blank-background-btn-' + submissionUuid" class="default-empty-file-upload">
        <div class="default-empty-file-upload-icon">
          <img class="default-empty-file-upload-icon-size" src="../../../assets/Iconography/UploadPurple.svg">
        </div>
        <div class="default-empty-file-upload-text">Drag and Drop here or click to upload a file</div>
      </label>
      <input *ngIf="files.length <= 0" [id]="'blank-background-btn-' + submissionUuid" type="file" style="display: none" (change)='upFile($event)' accept="image/jpeg, image/png, image/bmp, application/pdf">
      <table *ngIf="files.length > 0">
        <thead>
        <tr class="default-table-header">
          <th class="file-upload-file-name table-header">
            <div class="invoice-control-btn">
              <label [for]="'add-btn-' + submissionUuid" class="default-btn default-btn-clear default-control-btn">+ Add Invoice</label>
              <input [id]="'add-btn-' + submissionUuid" type="file" style="display: none" (change)='upFile($event)' accept="image/jpeg, image/png, image/bmp, application/pdf">
            </div>
          </th>
          <th class="file-upload-controls table-header"></th>
        </tr>
        </thead>
        <tbody class="file-upload-table-body">
        <tr *ngFor="let f of files; let fi = index" [ngClass]="{'file-upload-error-row': loading[fi].error}" class="default-table-row">
          <td class="default-table-text file-upload-data-file" (click)="clickInvoice(fi)">{{f.name}}<span *ngIf="loading[fi].error"> - {{loading[fi].errormsg}}</span></td>
          <td *ngIf="!loading[fi].loading" class="file-upload-controls delete-control" (click)="delete(fi)">
            <div class="invoice-control-container">
              <div class="default-delete-icon"><img src="../../../assets/Iconography/CrossWhite.svg"></div>
            </div>
          </td>
          <td *ngIf="loading[fi].loading" class="file-upload-controls loading-control">
            <div class="default-progress-bar-container">
              <div class="default-progress-bar" [ngStyle]="{'width': loading[fi].progress + '%'}"></div>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <app-file-drop (files)="droppedFiles($event)"></app-file-drop>
    </div>
    <div class="invoice-submission-row">
      <div class="invoice-submission-info">
        <div class="default-tooltip invoice-submission-tooltip-separator">i</div>
        <div class="default-paragraph-small">Acceptable formats include:.PDF, JPEG/JPG, BMP and PNG</div>
      </div>
      <div class="invoice-submission-btn-cont">
        <button *ngIf="files.length <= 0" class="default-btn default-btn-primary" (click)="clickEmpty()">Submit</button>
        <button *ngIf="!saveLoading && files.length > 0" class="default-btn default-btn-primary default-submit-btn" (click)="submit()">Submit</button>
        <button *ngIf="saveLoading && files.length > 0" class="default-btn default-btn-primary default-submit-btn">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
            class="loadingRotate"
            version="1.1"
            viewBox="0 0 56 56"
            height="5.292mm"
            width="5.292mm">
            <g
              transform="translate(-55.373511,-106.77655)">
              <path
                d="M 110.06486,141.6844 A 27.153975,26.689692 0 0 1 80.291831,161.23795 27.153975,26.689692 0 0 1 56.682161,134.7766 27.153975,26.689692 0 0 1 80.291831,108.31524 27.153975,26.689692 0 0 1 110.06486,127.8688"
                style="fill:none;fill-opacity:1;stroke:#FFFFFF;stroke-width:2.6173;stroke-linecap:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:115.842;stroke-opacity:1" />
            </g>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <app-footer></app-footer>
</div>
<div *ngIf="invoiceView" class="default-modal" [@fadeInOnEnter] [@fadeOutOnLeave]>
  <div class="default-modal-backdrop" (click)="closeInvoice()"></div>
  <div class="default-modal-card file-upload-image">
    <div class="default-modal-exit no-select" (click)="closeInvoice()"><img class="default-modal-exit-cross" src="../../../assets/Iconography/CrossWhite.svg"></div>
    <app-file-viewer [media]="this.processedMedia[focus]" style="width: 100%; height: 100%"></app-file-viewer>
  </div>
</div>
